const prefix = '@USERS_ONLINE';

export const SET_USERS_ONLINE = `${prefix}/SET_USERS_ONLINE`;
export const SET_USER_ONLINE = `${prefix}/SET_USER_ONLINE`;
export const SET_USER_OFFLINE = `${prefix}/SET_USER_OFFLINE`;

export const setUsersOnline = users => ({
    type: SET_USERS_ONLINE,
    payload: users,
});

export const setUserOnline = user => ({
    type: SET_USER_ONLINE,
    payload: user,
});

export const setUserOffline = user => ({
    type: SET_USER_OFFLINE,
    payload: user,
});
