import PropTypes from 'prop-types';
import React from 'react';

import './envelope-wrapper.scss';

const EnvelopeWrapper = ({ className, children }) => {
    return (
        <div className={`envelope-wrapper ${className}`}>
            {children}
        </div>
    );
};

EnvelopeWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default EnvelopeWrapper;