import classNames from 'classnames';
import React from 'react';
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';
import './range-input.scss';

const RangeInput = (props) => {
    const { key, label, input, max, min, step, rightText, primary, required, meta } = props;
    const { value, onChange, onFocus, onBlur } = input;
    const { touched, valid } = meta;

    return (<div className='range-input__wrap' key={key}>
        <label className='form__label'>{label}</label>
        <div className={classNames('string-input__input-wrap', {
            'string-input__input--primary': primary,
            'string-input__input--necessarily': required,
            'string-input__input--filled': !!value,
            'string-input__input--done': touched && valid,
            'string-input__input--error': touched && !valid,
        })}
        >
            <input
                type='number'
                step={step}
                min={min}
                max={max}
                value={value}
                className='base-input range-input__number'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {rightText && <span className='range-input__number-text'>{rightText}</span>}
            <Slider
                min={min}
                max={max}
                value={value}
                tooltip={false}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                className='range-input__range'
            />
        </div>
    </div>);
};

export default RangeInput;
