import React from 'react';

import './logo.scss';

const Logo = ({ isMain }) => {
    return <div className={`logo ${isMain ? 'main' : ''}`}>
        <img src='/assets/images/favicon.svg' />
        <span>Юридический сервис</span>
    </div>;
};

export default Logo;
