import { useState } from 'react';

export const useModal = () => {
    const [isOpened, setisOpened] = useState(false);

    const handleOpen = () => setisOpened(true);

    const handleClose = () => setisOpened(false);

    return [isOpened, handleOpen, handleClose];
};
