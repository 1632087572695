import { debounce } from 'debounce';
import React, { useCallback, useMemo } from 'react';
import { FormSpy } from 'react-final-form';

const FormValuesSpy = ({ onChange, debounceTime }) => {
    const onChangeDebounced = useMemo(() => debounceTime ? debounce(onChange, debounceTime) : onChange, [debounceTime, onChange]);
    const onChangeHandler = useCallback(({ values }) => onChangeDebounced(values), [onChangeDebounced]);

    return <FormSpy onChange={onChangeHandler} subscription={{ values: true }} />;
};

export default FormValuesSpy;
