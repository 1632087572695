import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUserSelector } from '@selectors/current-user-selectors';

import ChatAvatar, { ChatMessageItem } from '..';

import './chat-block.scss';

const ChatBlock = ({ messageData, isRead, isGeneralChat = true, hideReadMark, isOrder = false }) => {
    const { id: currentUserId } = useSelector(getCurrentUserSelector);

    return messageData.map(message => {
        const { id, content, user, created, is_another_user_read: isReadByAnotherUser, documents } = message;
        const { id: authorId, avatar, name } = user;

        const isMyMessage = authorId === currentUserId;

        return <div key={id}>
            <div className='user-message'>
                {!isMyMessage && <ChatAvatar avatarUrl={avatar} />}
                <div className='user-message__text'>
                    {!isMyMessage && <p className='user-message__name'>{name}</p>}
                    <ChatMessageItem
                        key={id}
                        id={id}
                        text={content}
                        time={created}
                        isRead={isRead}
                        isReadByAnotherUser={isReadByAnotherUser}
                        isMine={isMyMessage}
                        isGeneralChat={isGeneralChat}
                        documents={documents}
                        hideReadMark={hideReadMark}
                        isOrder={isOrder}
                    />
                </div>
            </div>
        </div>;
    });
};

export default ChatBlock;
