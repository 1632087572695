import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { StringInput } from '@form-components';
import { useForceUpdate } from '@hooks';
import AttentionSvg from '@images/attention.svg';
import ChatAddDocSvg from '@images/chat-add-doc.svg';
import ChatUploadDocSvg from '@images/chat-upload-doc.svg';
import CloseSvg from '@images/close.svg';
import SendSvg from '@images/send-message.svg';
import VectorChatSvg from '@images/vector-chat.svg';

import './message-control.scss';

const isImage = fileName => {
    const extension = fileName.split('.').reverse()[0];

    return ['png', 'jpg', 'jpeg'].includes(extension);
};

const FILE_MAX_SIZE = 20 * 1024 * 1024;

const MessageControl = ({ onSubmit }) => {
    const forceUpdate = useForceUpdate();
    const formRef = useRef();

    const fileInputRef = useRef();
    const [files, setFiles] = useState([]);

    const validateFiles = useCallback(files => {
        files.forEach(file => {
            if (file.size > FILE_MAX_SIZE) {
                file.error = 'Файл весит больше чем 20 Мб';
            }
        });
    }, []);

    const isSendDisabled = useMemo(() => !!files.find(file => file.error), [files]);

    const onSelectFilesHandler = useCallback(event => {
        if (event.target.files) {
            const newFiles = [...event.target.files];

            newFiles.forEach(file => {
                if (isImage(file.name)) {
                    const reader = new FileReader();

                    reader.onload = () => {
                        file.previewBase64 = reader.result;
                        forceUpdate();
                    };
                    reader.readAsDataURL(file);
                }
            });
            validateFiles(newFiles);
            setFiles([...files, ...event.target.files]);
        }
        fileInputRef.current.value = null;
    }, [files, forceUpdate, validateFiles]);

    const onRemoveFileHandler = useCallback(index => {
        const newFiles = [...files];

        newFiles.splice(index, 1);
        setFiles(newFiles);
    }, [files]);

    const onSubmitHandler = useCallback(values => {
        if (!isSendDisabled && values.content) {
            onSubmit({ ...values, files });
            setFiles([]);
            formRef.current.reset();
        }
    }, [files, isSendDisabled, onSubmit]);

    return <Form
        onSubmit={onSubmitHandler}
        render={({ handleSubmit, form, values }) => {
            formRef.current = form;

            return <form onSubmit={handleSubmit} className='message-control'>
                <div className='message-control__files'>
                    <input name='hidden-upload-files' type='file' ref={fileInputRef} onChange={onSelectFilesHandler} multiple={true} />
                    {files.length !== 0 && <div className='message-control__files-list'>
                        {files.map(({ name, previewBase64, error }, index) =>
                            <div className='message-control__file' key={index}>
                                {error && <div className='message-control__file-error'>
                                    <AttentionSvg /><br />{error}<br />({name})
                                </div>}
                                {!error && <div className='message-control__file-content'>
                                    {isImage(name) ?
                                        <img src={previewBase64} /> :
                                        <><ChatUploadDocSvg /><br />{name}</>
                                    }
                                </div>}
                                <button type='button' onClick={() => onRemoveFileHandler(index)}>
                                    <CloseSvg />
                                </button>
                            </div>,
                        )}
                        {files.length !== 10 &&
                            <button
                                className='message-control__add-file-button'
                                onClick={() => fileInputRef.current.click()}
                                type='button'
                            >
                                <ChatAddDocSvg />
                            </button>}
                    </div>}
                </div>
                <div className='message-control__input'>
                    <Field
                        name='content'
                        component={StringInput}
                        type='text'
                        placeholder='Введите сообщение'
                        rightIcon={<VectorChatSvg onClick={() => fileInputRef.current.click()} />}
                    />
                    <button
                        className={classNames('message-control__send-button', {
                            'message-control__send-button_disabled': isSendDisabled || !values.content,
                        })}
                    >
                        <SendSvg />
                    </button>
                </div>
            </form>;
        }}
    />;
};

export default MessageControl;
