import qs from 'qs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { getSpecialists } from 'src/api';

import { Button, Modal, Pagination, Table, unexpectedExceptionNotification } from '@components';
import { SPECIALIST_STATUS } from '@constants';
import CheckIcon from '@images/check.svg';
import PlusIcon from '@images/plus.svg';
import RefreshIcon from '@images/refresh.svg';

import SpecialistModalFilters from './specialist-modal-filters';

import './add-specialist-modal.scss';

const AddSpecialistModal = ({ onClose, value, onChange, isAddedSpecialistsActive }) => {
    const [tableSpecialistsData, setTableSpecialistsData] = useState([]);
    const [addedSpecialists, setAddedSpecialists] = useState([]);

    const onAddSpecialistHandler = useCallback(
        specialist => {
            if (isAddedSpecialistsActive) {
                specialist.is_user_active = SPECIALIST_STATUS.ACTIVE;
            }
            setAddedSpecialists([...addedSpecialists, specialist]);
        },
        [addedSpecialists, isAddedSpecialistsActive],
    );

    const columns = useMemo(
        () => [
            {
                key: 'avatar',
                name: 'Фото',
                cellClassName: 'add-specialist-modal__avatar',
                cellRender({ value }) {
                    return (
                        <span>
                            <img src={value} />
                        </span>
                    );
                },
            },
            {
                key: 'info',
                name: 'ФИО',
                cellClassName: 'add-specialist-modal__info',
                cellRender({ row: { last_name, first_name, middle_name, legal_case_category } }) {
                    return (
                        <span>
                            <div className='add-specialist-modal__info_last-name'>{last_name}</div>
                            <div className='add-specialist-modal__info_first-and-middle-name'>
                                {first_name} {middle_name}
                            </div>
                            {legal_case_category?.name && <div className='add-specialist-modal__info_specialist-type'>{legal_case_category.name}</div>}
                        </span>
                    );
                },
            },
            {
                key: 'add-action',
                name: 'Добавить',
                cellClassName: 'add-specialist-modal__action',
                cellRender({ row }) {
                    const isAdded = !!value.find(({ id }) => id === row.id) || !!addedSpecialists.find(({ id }) => id === row.id);

                    return <div>{isAdded ? <CheckIcon /> : <PlusIcon onClick={() => onAddSpecialistHandler(row)} className='pointer' />}</div>;
                },
            },
        ],
        [addedSpecialists, onAddSpecialistHandler, value],
    );

    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState({});
    const prevQueryParams = useRef(null);

    useEffect(() => {
        const fetchSpecialists = async () => {
            const queryParams = qs.stringify({ flt_name: filters?.search, per_page: 3, page: currentPage + 1 });

            try {
                if (prevQueryParams.current === queryParams) {
                    return;
                }
                const {
                    data: {
                        data: { specialists },
                    },
                } = await getSpecialists(queryParams);

                setTableSpecialistsData(specialists);
            } catch (ex) {
                if (ex?.response?.status) {
                    unexpectedExceptionNotification();
                }
            }

            prevQueryParams.current = queryParams;
        };

        fetchSpecialists();
    }, [currentPage, filters]);

    const onSaveHandler = useCallback(() => {
        onChange([...value, ...addedSpecialists]);
        onClose();
    }, [addedSpecialists, onChange, onClose, value]);

    const onFiltersChangeHandler = useCallback(filters => {
        setFilters(filters);
        setCurrentPage(0);
    }, []);

    return (
        <Modal title='Добавление участника' open={true} onClose={onClose}>
            <div className='add-specialist-modal__body'>
                <SpecialistModalFilters onChange={onFiltersChangeHandler} />
                <Table columns={columns} data={tableSpecialistsData.data} emptyPlaceholder='Список пуст' />
                {tableSpecialistsData.meta && tableSpecialistsData.meta.last_page !== 1 &&
                    <Pagination
                        forcePage={currentPage}
                        onPageChange={event => setCurrentPage(event.selected)}
                        lastPage={tableSpecialistsData.meta.last_page}
                    />
                }
            </div>

            <div className='add-specialist-modal__controls'>
                <Button view='primary-outline' size='medium' type='button' onClick={onClose} startIcon={<RefreshIcon />}>
                    Отменить
                </Button>
                <Button size='medium' type='button' onClick={onSaveHandler}>
                    Сохранить
                </Button>
            </div>
        </Modal>
    );
};

export default AddSpecialistModal;
