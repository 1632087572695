import { ru } from 'date-fns/locale';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import { Modal } from '@material-ui/core';

import 'react-day-picker/dist/style.css';
import './date-range-picker.scss';

const pastMonth = new Date();

const DateRangePicker = ({ isOpened, onClose, onSelect, selected, numberOfMonths, className }) => {
    return <Modal style={{ position: 'absolute', top: '30px' }} BackdropProps={{ invisible: true }} open={isOpened} onClose={onClose} className={className} disablePortal={true} >
        <DayPicker
            id='picker'
            mode='range'
            onSelect={onSelect}
            selected={selected}
            locale={ru}
            showOutsideDays={true}
            defaultMonth={pastMonth}
            numberOfMonths={numberOfMonths}
        />
    </Modal>;
};

export default DateRangePicker;
