import classNames from 'classnames';
import React from 'react';

import './textarea-input.scss';

const TextAreaInput = ({ key, label, input, primary, required, meta, placeholder, help, secondary }) => {
    const { value } = input;
    const { touched, valid, active, submitError, dirtySinceLastSubmit } = meta;

    const error = meta.error || (!dirtySinceLastSubmit && submitError);
    const helpText = touched && error || help;

    return (
        <div key={key} className='textarea-input' >
            <label className='form__label'>{label}</label>
            <div
                key={key}
                className={classNames('textarea-input__control', {
                    'textarea-input__control--primary': primary,
                    'textarea-input__control--necessarily': required,
                    'textarea-input__control--filled': !!value,
                    'textarea-input__control--focused': active,
                    'textarea-input__control--done': touched && valid,
                    'textarea-input__control--error': touched && !valid,
                    'textarea-input__control--secondary': secondary,
                })}
            >
                <textarea {...input} placeholder={placeholder} />
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && error })}>{helpText}</div>}
        </div>
    );
};

export default TextAreaInput;
