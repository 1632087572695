import React from 'react';

import './tabs-input.scss';

const TabsInput = ({ input, tabs }) => {
    const { value, onChange } = input;

    return <div className='tabs-input'>
        {tabs && tabs.map(element => {
            return (
                <div
                    className={`tabs-input__item ${element.key === value ? 'active' : ''}`}
                    onClick={() => onChange(element.key)}
                    key={element.key}
                >
                    {element.label}
                </div>
            );
        })}
    </div>;
};

export default TabsInput;
