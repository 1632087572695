import classNames from 'classnames';
import React, { useMemo } from 'react';
import InputMask from 'react-input-mask';

import { WarningTooltip } from '@components';
import CheckIcon from '@images/check.svg';

import './string-input.scss';

const StringInput = props => {
    const { label, input, meta, placeholder, tooltip, mask, help, rightIcon = null, required, primary, disabled, autoComplete, secondary, className } = props;
    const { touched, submitError, dirtySinceLastSubmit } = meta;
    const { onChange, onBlur, onFocus, value, type, name } = input;

    const error = meta.error || (!dirtySinceLastSubmit && submitError);
    const helpText = touched && error || help;

    const inputComponent = useMemo(() => <InputMask
        value={value}
        id={name}
        name={name}
        type={type}
        mask={mask}
        className={classNames('base-input string-input__input', {
            // filled: !!value,
            'local-error': touched && error,
        })}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
    />, [autoComplete, disabled, error, mask, name, onBlur, onChange, onFocus, placeholder, touched, type, value]);

    return (
        <div data-test-id={props['data-test-id']} className={`string-input ${className}`}>
            {label && <label htmlFor={name} className='form__label'>
                {label}
            </label>}
            <div className={classNames('string-input__input-wrap', {
                'string-input__input--primary': primary,
                'string-input__input--necessarily': required,
                'string-input__input--done': touched && !error,
                'string-input__input--error': touched && error,
                'string-input__input--disabled': disabled,
                'string-input__input--secondary': secondary,
            })}>
                {tooltip ? <WarningTooltip
                    arrow={true}
                    {...tooltip}
                >
                    {inputComponent}
                </WarningTooltip> : inputComponent}
                <div className='string-input__right-icon' >
                    {!value.length || rightIcon || touched && !error && <CheckIcon />}
                    {rightIcon && rightIcon}
                </div>
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && error })}>{helpText}</div>}
        </div>
    );
};

export default StringInput;
