import classNames from 'classnames';
import Link from 'next/link';
import React, { forwardRef, useCallback } from 'react';

import './Button.scss';

const Button = forwardRef(({
    children,
    onClick,
    startIcon,
    endIcon,
    isLoading = false,

    view = 'primary',
    size = 'large',

    className,
    disabled,
    ...attrs
}, ref) => {
    const onClickAction = useCallback(e => {
        if (disabled) {
            e.preventDefault();
        } else if (onClick) {
            return onClick(e);
        }
    }, [disabled, onClick]);

    const classes = classNames(
        'btn',
        `btn-${view}`,
        `btn-${size}`,
        className,
        {
            'btn-disabled': disabled || isLoading,
        },
    );

    const Tag = attrs.to ? Link : 'button';

    return (
        <Tag
            ref={ref}
            className={classes}
            disabled={disabled || isLoading}
            onClick={onClickAction}
            {...attrs}
        >
            {startIcon}{isLoading && <span className={`btn__loader btn__loader-${view}`} />}{children}{endIcon}
        </Tag>
    );
});

export default Button;
