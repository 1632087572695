import axios from 'axios';

import { unexpectedExceptionNotification } from '@components';

export const setPersonalData = data => {
    return {
        type: 'PERSONAL_STATE',
        payload: data,
    };
};

export const getOrdersAction = data => {
    return {
        type: 'ORDER_STATE',
        payload: data,
    };
};

export const getOrderResponseAction = data => {
    return {
        type: 'ORDER_RESPONSE_STATE',
        payload: data,
    };
};

export const setPersonalBlockDataAction = data => {
    return {
        type: 'PERSONAL_MY_DATA_INFORMATION',
        payload: data,
    };
};

export const setPersonalFormDataAction = data => {
    return {
        type: 'PERSONAL_MY_DATA_FORM',
        payload: data,
    };
};

export const setPersonalDocumentFormAction = data => {
    return {
        type: 'PERSONAL_MY_ANOTHER_DOCUMENT_FORM',
        payload: data,
    };
};

export const setPersonalCounters = data => {
    return {
        type: 'PERSONAL_COUNTERS',
        payload: data,
    };
};

export const getPersonalData = () => async dispatch => {
    try {
        const { data } = await axios.get('/api/site/personal/data');

        dispatch(setPersonalData(data?.data));
    } catch (error) {
        unexpectedExceptionNotification();
    }
};

export const getPersonalCounters = () => async dispatch => {
    try {
        const { data } = await axios.get('/api/user/counters');

        dispatch(setPersonalCounters(data?.data));
    } catch (error) {
        unexpectedExceptionNotification();
    }
};
