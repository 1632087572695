import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import './Tag.scss';

const Tag = ({ primary, onDelete = false, className, children, onClick }) => {
    const classes = classNames('tag', {
        'tag-primary': primary,
    });

    return (
        <Link href='/'>
            <div className={`${classes} ${className}`}>
                {children}
                {onDelete && <svg onClick={onDelete} width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM8.625 7.575L7.575 8.625L6 7.05L4.425 8.625L3.375 7.575L4.95 6L3.375 4.425L4.425 3.375L6 4.95L7.575 3.375L8.625 4.425L7.05 6L8.625 7.575Z' fill='#B0B3BC' />
                </svg>}
            </div>
        </Link>
    );
};

Tag.propTypes = {
    primary: PropTypes.bool,
    onDelete: PropTypes.func,
    className: PropTypes.string,
};

Tag.defaultProps = {
    primary: false,
    onDelete: null,
    className: '',
};

export default Tag;
