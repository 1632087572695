import classNames from 'classnames';
import { ru } from 'date-fns/locale';
import React, { useState } from 'react';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import '../date-picker-input/date-picker-input.scss';

const DateTimePickerInput = props => {
    const { label, input, meta, help, required } = props;
    const { value, onFocus, onChange, onBlur, name } = input;
    const { touched, valid, active, error, submitError } = meta;

    const [calendarActive, setCalendarActive] = useState(false);

    const helpText = touched && (error || submitError) || help;

    return (
        <div
            className={classNames('date-picker-input', {
                'date-picker-input_focused': active || calendarActive,
                'date-picker-input_filled': !!value,
                'date-picker-input_error': touched && !valid,
            })}
            data-test-id={props['data-test-id']}
        >
            <label htmlFor={name} className='string-input__label'>
                {label}
            </label>
            <div
                className={classNames('string-input__input-wrap', {
                    'string-input__input--necessarily': required,
                })}
            >
                <MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        id={name}
                        name={name}
                        variant='inline'
                        onError={console.log}
                        format='dd.MM.yyyy HH:mm'
                        placeholder='__.__.____ __:__'
                        value={value || null}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                        onOpen={() => setCalendarActive(true)}
                        onClose={() => setCalendarActive(false)}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
        </div>
    );
};

export default DateTimePickerInput;
