import classnames from 'classnames';
import React from 'react';

import './divide-line.scss';

const DivideLine = ({ label, isBlue }) => {
    const style = classnames('divide-line', { 'divide-line_blue': isBlue });

    return <div className={style}><p>{label}</p></div>;
};

export default DivideLine;
