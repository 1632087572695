import axios from 'axios';
import { useEffect, useState } from 'react';

export const usePaginationScrollSelect = apiUrl => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchOptions = async () => {
            setIsLoading(true);
            const response = await axios.get(apiUrl, {
                params: {
                    page,
                },
            });

            const newOptions = response.data.data ? response.data.data : response.data;

            setOptions(prevOptions => [...prevOptions, ...newOptions]);

            if (response.data.meta) {
                setHasMore(page < response.data.meta.last_page);
            } else {
                setHasMore(false);
            }
            setIsLoading(false);
        };

        if (hasMore) {
            fetchOptions();
        }
    }, [apiUrl, hasMore, page]);

    const handleScroll = event => {
        const { target } = event;

        if (target.scrollHeight - target.scrollTop === target.clientHeight && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    return [options, handleScroll, isLoading];
};
