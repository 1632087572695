import React from 'react';

import './Cell.scss';

const Cell = ({ value, isActive, isTaken, isZoned, isHeaderCell, onClick }) => {
    return (
        <div onClick={onClick} className={`cell ${isActive && 'cell-active'} ${isTaken && 'cell-taken'} ${isZoned && 'cell-zoned'}`}>
            <p className={`cell__text ${isHeaderCell && 'cell__text-header'} ${(isActive || isTaken) && 'cell__text-white'}`}>{value}</p>
        </div>
    );
};

export default Cell;
