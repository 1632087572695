import { Tooltip as MaterialTooltip, withStyles } from '@material-ui/core';

export const Tooltip = withStyles(theme => ({
    arrow: {
        color: '#fff',
    },
    tooltip: {
        backgroundColor: '#fff',
        color: '#5A5C68',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
    },
}))(MaterialTooltip);

export const WarningTooltip = withStyles(theme => ({
    arrow: {
        color: '#F9EEEF',
    },
    tooltip: {
        backgroundColor: '#F9EEEF',
        color: '#DC0A0A',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(MaterialTooltip);
