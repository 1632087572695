import * as R from '../utils/ramda';

const DEFAULT_MESSAGE = 'Неверный формат email';

// eslint-disable-next-line no-control-regex
const pattern =
    /^((([a-z]|\d|[!#$%&'+\-/=^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'+\-/=^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const enoughCharsAfterAt = (value) => value.substring(value.indexOf('@') + 1, value.lastIndexOf('.')).length > 1;
const enoughCharsAfterLastPoint = (value) => value.substring(value.lastIndexOf('.') + 1).length > 1;
const emailSuitableForPattern = R.test(pattern);
const emailContainsRusChars = R.test(/[А-Яа-яЁё]/);
const emailNotContainsRusChars = R.complement(emailContainsRusChars);

export const emailValidator = (message = DEFAULT_MESSAGE) => {
    return (value) => {
        if (!value) {
            return null;
        }

        return R.allPass([emailSuitableForPattern, enoughCharsAfterAt, enoughCharsAfterLastPoint, emailNotContainsRusChars])(value) ? null : message;
    };
};
