import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import ExpandIcon from '@images/accordion-dropdown.svg';

import './accordion.scss';

const AccordionComponent = props => {
    const { items = [] } = props;

    const [expanded, setExpanded] = useState();

    return (
        <div className='Accordion'>
            {items.map(item =>
                <Accordion
                    key={item.key}
                    expanded={expanded === item.key}
                    onChange={() => setExpanded(expanded === item.key ? null : item.key)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandIcon />}
                    >
                        {item.label}
                    </AccordionSummary>
                    <AccordionDetails>
                        {item.content}
                    </AccordionDetails>
                </Accordion>)}

        </div>);
};

export default AccordionComponent;
