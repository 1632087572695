const DEFAULT_MESSAGE = 'Неверный формат даты';

export const dateValidator = (msg = DEFAULT_MESSAGE) => {
    return value => {
        if (value.toString() === 'Invalid Date') {
            return msg;
        }

        return null;
    };
};

export const minDateValidator = (minDate = new Date(), msg = 'Дата должна быть больше текущей') => {
    return value => {
        if (value <= minDate) {
            return msg;
        }

        return null;
    };
};
