import qs from 'qs';

import { createNotification, successNotification, unexpectedExceptionNotification } from '@components';
import { notificationStatuses } from '@constants';
import { getCasePageSelector } from '@selectors/case-page-selector';
import { getTabDocumentsSelector } from '@selectors/tab-documents-selector';
import axios from '@utils/axios';

const prefix = '@TAB_DOCUMENTS';

export const SET_SELECTED_DOCUMENT_ID = `${prefix}/SET_SELECTED_DOCUMENT_ID`;
export const SET_SEARCH = `${prefix}/SET_SEARCH`;
export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;

export const SET_IS_GET_DOCUMENTS_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_DOCUMENTS_REQUEST_PROCESSING`;
export const SET_GET_DOCUMENTS_REQUEST_ERROR = `${prefix}/SET_GET_DOCUMENTS_REQUEST_ERROR`;
export const SET_DOCUMENTS = `${prefix}/SET_DOCUMENTS`;

export const SET_IS_GET_EDIT_DOCUMENT_FORM_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_EDIT_DOCUMENT_FORM_REQUEST_PROCESSING`;
export const SET_GET_EDIT_DOCUMENT_FORM_REQUEST_ERROR = `${prefix}/SET_GET_EDIT_DOCUMENT_FORM_REQUEST_ERROR`;
export const SET_EDIT_DOCUMENT_FORM = `${prefix}/SET_EDIT_DOCUMENT_FORM`;

export const setFiltersAction = filters => ({
    type: SET_SEARCH,
    payload: filters,
});

export const setCurrentPageAction = selectedPage => ({
    type: SET_CURRENT_PAGE,
    payload: selectedPage,
});

export const setSelectedDocumentIdAction = documentId => ({
    type: SET_SELECTED_DOCUMENT_ID,
    payload: documentId,
});

/*GET FORM FOR EDIT SELECTED DOCUMENT*/
const setIsGetEditDocumentFormRequestProcessingAction = isGetEditDocumentFormRequestProcessing => ({
    type: SET_IS_GET_EDIT_DOCUMENT_FORM_REQUEST_PROCESSING,
    payload: isGetEditDocumentFormRequestProcessing,
});

const setGetEditDocumentFormRequestErrorAction = error => ({
    type: SET_GET_EDIT_DOCUMENT_FORM_REQUEST_ERROR,
    payload: error,
});

const setEditDocumentFormAction = data => ({
    type: SET_EDIT_DOCUMENT_FORM,
    payload: data,
});

export const getEditDocumentFormAction = () => async (dispatch, getState) => {
    dispatch(setIsGetEditDocumentFormRequestProcessingAction(true));

    try {
        const { selectedDocumentId } = getTabDocumentsSelector(getState());
        const { data } = await axios.get(`/api/legal_case_documents/${selectedDocumentId}/edit`);

        dispatch(setEditDocumentFormAction(data));
    } catch (error) {
        dispatch(setGetEditDocumentFormRequestErrorAction(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetEditDocumentFormRequestProcessingAction(false));
};

/*GET DOCUMENTS WITH PAGINATION*/
const setIsGetDocumentsRequestProcessingAction = isGetDocumentsRequestProcessing => ({
    type: SET_IS_GET_DOCUMENTS_REQUEST_PROCESSING,
    payload: isGetDocumentsRequestProcessing,
});

const setGetDocumentsRequestErrorAction = error => ({
    type: SET_GET_DOCUMENTS_REQUEST_ERROR,
    payload: error,
});

const setDocumentsAction = documents => ({
    type: SET_DOCUMENTS,
    payload: documents,
});

export const getDocumentsAction = isPersonalTab => async (dispatch, getState) => {
    dispatch(setIsGetDocumentsRequestProcessingAction(true));
    const { filters, currentPage } = getTabDocumentsSelector(getState());
    const { caseId } = getCasePageSelector(getState());

    try {
        const queryParams = { ...filters, per_page: 10, page: currentPage + 1 };

        let url = `/api/legal_cases/${caseId}/documents?${qs.stringify(queryParams)}`;

        if (isPersonalTab) {
            url = `/api/legal_case_documents?${qs.stringify(queryParams)}`;
        }

        const { data } = await axios.get(url);

        dispatch(setDocumentsAction(data));
    } catch (error) {
        dispatch(setGetDocumentsRequestErrorAction(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetDocumentsRequestProcessingAction(false));
};

/* DETACH, DELETE, ADD, EDIT DOCUMENTS */
export const detachDocumentAction = (documentId, onSuccess, isPersonalTab) => async (dispatch, getState) => {
    try {
        const { caseId } = getCasePageSelector(getState());

        let url = `/api/legal_cases/${caseId}/documents/${documentId}`;

        if (isPersonalTab) {
            url = `/api/legal_case_documents/${documentId}`;
        }
        await axios.delete(url);
        onSuccess();
        successNotification();
    } catch (err) {
        if (err.response.status === 422) {
            err.response.data.errors.map(error => createNotification(error, notificationStatuses.error));
        } else if (err.response.status === 403) {
            createNotification('Доступ запрещён', notificationStatuses.error);
        } else {
            unexpectedExceptionNotification();
        }
    }
};

export const editDocumentAction = (editDocument, onSuccess) => async (dispatch, getState) => {
    try {
        const { selectedDocumentId } = getTabDocumentsSelector(getState());

        await axios.post(`/api/legal_case_documents/${selectedDocumentId}`, editDocument);
        onSuccess();
        successNotification();
    } catch (error) {
        unexpectedExceptionNotification();
    }
};
