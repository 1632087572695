import classNames from 'classnames';
import React, { useCallback } from 'react';

import './switch.scss';

const Switch = ({ name, value, onChange }) => {
    const onChangeHandler = useCallback(event => {
        onChange(event.target.checked);
    }, [onChange]);

    return <label className='switch'>
        <input
            id={name}
            name={name}
            className={classNames('switch__input', { 'switch__input--active': value })}
            type='checkbox'
            checked={value}
            onChange={onChangeHandler}
        />
        <span className='switch__slider'></span>
    </label>;
};

export default Switch;
