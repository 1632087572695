import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useCallback } from 'react';

import { Button } from '@components';

import './image-file-input.scss';

const ImageFileInput = props => {
    const { label, input, meta, help } = props;
    const { value, onChange, onBlur, name } = input;
    const { touched, valid, error, submitError } = meta;

    const [previewBase64, setPreviewBase64] = useState(null);

    const onChangeFileInput = useCallback(file => {
        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            onChange(file);
            onBlur();

            setPreviewBase64(reader.result);
        };
        reader.readAsDataURL(file);
    }, [onBlur, onChange]);

    const inputRef = useRef();

    const onDeleteFile = useCallback(event => {
        event.preventDefault();
        setPreviewBase64(null);
        inputRef.current.value = '';
        onChange(null);
        onBlur();
    }, [onBlur, onChange]);

    const [isDragEnter, setIsDragEnter] = useState(false);

    const onDragEnter = useCallback(() => {
        setIsDragEnter(true);
    }, []);

    const onDragLeave = useCallback(() => {
        setIsDragEnter(false);
    }, []);

    const onDragOver = useCallback(event => {
        event.stopPropagation();
        event.preventDefault();
        //setIsDragEnter(false);
    }, []);

    const onDrop = useCallback(event => {
        event.stopPropagation();
        event.preventDefault();

        onChangeFileInput(event.dataTransfer.files[0]);
    }, [onChangeFileInput]);

    const helpText = touched && (error || submitError) || help;

    return (
        <div className={classNames('file-input', { 'file-input_drag-enter': isDragEnter })}>
            <label htmlFor={name} className='form__label'>{label}</label>
            <div className='file-input__input-wrap'
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
            >
                <label
                    htmlFor={name} className={`file-area-label ${touched && !valid ? 'file-area-label--error' : ''}`}
                >
                    {value && valid
                        ? <>
                            {(typeof value === 'string' || previewBase64) && <img
                                src={typeof value === 'string' && value || previewBase64}
                                className='file-area-image'
                            />}
                            <Button view='primary-outline' onClick={onDeleteFile} className='file-area-button'>Удалить</Button>
                        </>
                        :
                        <h3 className={`file-area-title ${touched && !valid ? 'file-area-title--error' : ''}`} style={{ pointerEvents: 'none' }}>
                            {helpText}
                        </h3>
                    }
                </label>
                <input name={name} ref={inputRef} onChange={event => onChangeFileInput(event.target.files[0])} id={name} className='file-area-file' type='file' accept='image/*' />
            </div>
        </div>
    );
};

export default ImageFileInput;
