import JsCookie from 'js-cookie';
import Echo from 'laravel-echo';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsSelector } from 'src/selectors/notification-selector';
import { getPrivateChatSelector } from 'src/selectors/private-chat-selector';
import { getPrivateChatsSelector } from 'src/selectors/private-chats-selectors';
import { getPrivateOrderChatSelector } from 'src/selectors/private-order-chat-selector';

import { setUnreadMessagesCount } from '@actions/auth/updateCurrentUser';
import { addUnreadMessageToCount, removeUnreadMessagesFromCount, setMessagesCounts } from '@actions/case-page';
import {
    addNewReadMessage as addNewReadGeneralMessage,
    addNewUnreadMessage as addNewUnreadGeneralMessage,
    setMessagesRead as setGeneralMessagesRead,
} from '@actions/general-chat';
import { getCountNotifications, getNotifications } from '@actions/notifications';
import { setPersonalCounters } from '@actions/personal/personal';
import {
    addNewReadMessage as addNewReadPrivateMessage,
    addNewUnreadMessage as addNewUnreadPrivateMessage,
    setMessagesRead as setPrivateMessagesRead,
} from '@actions/private-chat';
import { changeLastMessageAndCount, removeReadMessagesFromCount } from '@actions/private-chats';
import {
    addNewReadMessage as addNewReadPrivateOrderMessage,
    addNewUnreadMessage as addNewUnreadPrivateOrderMessage,
    setMessagesRead as setPrivateOrderMessagesRead,
    updateMessageCounter,
} from '@actions/private-order-chat';
import { setUserOffline, setUserOnline, setUsersOnline } from '@actions/users-online';
import { getCasePageSelector } from '@selectors/case-page-selector';
import { getCurrentUserSelector } from '@selectors/current-user-selectors';
import { getGeneralChatSelector } from '@selectors/general-chat-selectors';

// TO DO: разобраться почему не пробрасывается значение через cross-env и убрать хардкод
const apiHost = process.env.API_HOST || ('production' && 'https://danceocean.ru');
const apiHostWithoutProtocol = apiHost?.replace?.('http://', '')?.replace?.('https://', '');

const options = {
    broadcaster: 'pusher',
    key: 'sadfasdfdsaf',
    cluster: 'mt1',
    forceTLS: false,
    httpHost: apiHostWithoutProtocol,
    httpsHost: apiHostWithoutProtocol,
    wsHost: apiHostWithoutProtocol,
    wssHost: apiHostWithoutProtocol,
    wsPort: 30012,
    wssPort: 30012,
    enabledTransports: ['ws', 'wss'],
    encrypted: false,
    authEndpoint: `${apiHost}/api/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: JsCookie.get('Authorization'),
            Accept: 'application/json',
        },
    },
};

const getActions = (privateChatId, generalChatId, privateOrderChatId) => {
    if (privateChatId) {
        return {
            addNewReadMessage: addNewReadPrivateMessage,
            addNewUnreadMessage: addNewUnreadPrivateMessage,
            setMessagesRead: setPrivateMessagesRead,
        };
    }

    if (generalChatId) {
        return {
            addNewReadMessage: addNewReadGeneralMessage,
            addNewUnreadMessage: addNewUnreadGeneralMessage,
            setMessagesRead: setGeneralMessagesRead,
        };
    }

    if (privateOrderChatId) {
        return {
            addNewReadMessage: addNewReadPrivateOrderMessage,
            addNewUnreadMessage: addNewUnreadPrivateOrderMessage,
            setMessagesRead: setPrivateOrderMessagesRead,
        };
    }

    return {};
};

const WebsocketWatcher = () => {
    const dispatch = useDispatch();
    const { id: userId } = useSelector(getCurrentUserSelector);
    const { chatId: generalChatId } = useSelector(getGeneralChatSelector);
    const { chatId: privateChatId } = useSelector(getPrivateChatSelector);
    const { chatId: privateOrderChatId } = useSelector(getPrivateOrderChatSelector);
    const { caseId } = useSelector(getCasePageSelector);
    const { chats } = useSelector(getPrivateChatsSelector);
    const { isNotificationOpened } = useSelector(getNotificationsSelector);

    const echoRef = useRef();
    const generalChatIdRef = useRef();
    const privateChatIdRef = useRef();
    const privateOrderChatIdRef = useRef();
    const caseIdRef = useRef();
    const chatsRef = useRef();

    useEffect(() => {
        generalChatIdRef.current = generalChatId;
    }, [generalChatId]);
    useEffect(() => {
        privateChatIdRef.current = privateChatId;
    }, [privateChatId]);
    useEffect(() => {
        privateOrderChatIdRef.current = privateOrderChatId;
    }, [privateOrderChatId]);
    useEffect(() => {
        caseIdRef.current = caseId;
    }, [caseId]);
    useEffect(() => {
        chatsRef.current = chats;
    }, [chats]);

    // обновляет счетчики в названиях вкладок и счетчик рядом с аватаркой
    const updateMessagesCounts = useCallback(
        event => {
            if (caseIdRef.current === event.legal_case_id) {
                dispatch(
                    setMessagesCounts(
                        event.number_of_messages_in_general_chat,
                        event.number_of_messages_in_private_chats,
                        event.number_of_unread_messages_in_general_chat,
                        event.number_of_unread_messages_in_private_chats,
                    ),
                );
            }

            dispatch(setUnreadMessagesCount(event.number_of_unread_messages));
        },
        [dispatch],
    );

    // Проверяет чат на наличие в списке приватных чатов дела
    const isPrivateChat = useCallback(chatId => chatsRef.current.find(({ id }) => id === chatId), []);

    useEffect(() => {
        if (userId) {
            echoRef.current = new Echo(options);
            if (window) {
                window.echo = echoRef.current;
            }

            echoRef.current
                .private(`user.${userId}`)
                .listen('LegalCaseChatMessageCreated', event => {
                    const { message } = event;

                    if (message) {
                        const {
                            legal_case_chat_id: chatIdFromMessage,
                            user: { id: userIdFromMessage },
                        } = message;

                        const chatId = privateChatIdRef.current || generalChatIdRef.current;
                        const { addNewReadMessage, addNewUnreadMessage } = getActions(privateChatIdRef.current, generalChatIdRef.current);

                        if (chatId === chatIdFromMessage) {
                            if (userIdFromMessage === userId) {
                                dispatch(addNewReadMessage?.(message));
                            } else {
                                dispatch(addNewUnreadMessage?.(message));
                            }
                        }

                        // меняем последнее сообщеине в приватном чате и
                        // счетчик прочитанных сообщений для конкретного приватного чата в списке чатов,
                        // если это не сообщение текущего пользователя
                        if (isPrivateChat(chatIdFromMessage)) {
                            dispatch(changeLastMessageAndCount(userId, message));
                        }

                        // меняем счетчик прочитанных в блоке "рабочая группа"
                        dispatch(addUnreadMessageToCount(userId, message));
                    }

                    updateMessagesCounts(event);
                })
                .listen('LegalCaseChatMessageRead', event => {
                    const { chat_id: chatIdFromEvent, user_who_read_id: userWhoReadId } = event;

                    const chatId = privateChatIdRef.current || generalChatIdRef.current;
                    const { setMessagesRead } = getActions(privateChatIdRef.current, generalChatIdRef.current);

                    if (chatIdFromEvent === chatId /* && userWhoReadId !== userId*/) {
                        dispatch(setMessagesRead?.(event.read_messages || []));
                    }

                    // меняет счетчик непрочитанных сообщений в приватном чате в списке чатов
                    if (isPrivateChat(chatIdFromEvent) && userWhoReadId === userId) {
                        dispatch(removeReadMessagesFromCount(chatIdFromEvent, event.read_messages?.length || 0));
                    }

                    // меняем счетчик прочитанных в блоке "рабочая группа"
                    dispatch(removeUnreadMessagesFromCount(chatIdFromEvent, event.read_messages?.length || 0));

                    updateMessagesCounts(event);
                })
                .listen('OrderChatMessageCreated', event => {
                    const { message } = event;

                    if (message) {
                        const {
                            order_chat_id: chatIdFromMessage,
                            user: { id: userIdFromMessage },
                        } = message;
                        const chatId = privateOrderChatIdRef.current;
                        const { addNewReadMessage, addNewUnreadMessage } = getActions(
                            privateChatIdRef.current,
                            generalChatIdRef.current,
                            privateOrderChatIdRef.current,
                        );

                        if (chatId === chatIdFromMessage) {
                            if (userIdFromMessage === userId) {
                                dispatch(addNewReadMessage?.(message));
                            } else {
                                dispatch(addNewUnreadMessage?.(message));
                            }
                        }
                        dispatch(updateMessageCounter());
                    }
                })
                .listen('OrderChatMessageRead', event => {
                    const { chat_id: chatIdFromEvent, user_who_read_id: userWhoReadId } = event;

                    const chatId = privateOrderChatIdRef.current;
                    const { setMessagesRead } = getActions(privateChatIdRef.current, generalChatIdRef.current, privateOrderChatIdRef.current);

                    if (chatIdFromEvent === chatId /* && userWhoReadId !== userId*/) {
                        dispatch(setMessagesRead?.(event.read_messages || []));
                    }
                })
                .listen('.App\\Events\\User\\UserCountersUpdatedEvent', event => {
                    const { data } = event;

                    if (data) {
                        dispatch(setPersonalCounters(data));
                    }
                })
                .listen('.user.counter.notification.updated', event => {
                    const { data } = event;

                    if (data.is_update_counter_notification) {
                        if (isNotificationOpened) {
                            dispatch(getCountNotifications());
                            dispatch(getNotifications());
                        } else {
                            dispatch(getCountNotifications());
                        }
                    }
                });

            echoRef.current
                .join('general')
                .here(users => {
                    dispatch(setUsersOnline(users));
                })
                .joining(user => {
                    dispatch(setUserOnline(user));
                })
                .leaving(user => {
                    dispatch(setUserOffline(user));
                });
        }

        if (!userId) {
            if (echoRef.current) {
                // if (userId) {
                //     echoRef.current.leave(`user.${userId}`);
                // }
                echoRef.current.disconnect();
            }
        }
    }, [dispatch, isNotificationOpened, isPrivateChat, updateMessagesCounts, userId]);

    return null;
};

export default WebsocketWatcher;
