import * as R from '@utils/ramda';

import { composeValidators } from './composeValidators';
import { maxLength } from './maxLength';
import { minLength } from './minLength';

const DEFAULT_MESSAGE = 'Поле может содержать только буквы и тире';

const checkNotChars = R.test(/[^а-яёa-z\-]/gi);
const checkNotDoubleDashes = R.test(/(-){2}/g);
const isValid = R.anyPass([checkNotDoubleDashes, checkNotChars]);

const onlyLettersAndDashes = (message = DEFAULT_MESSAGE) => {
    return (value) => {
        if (!value) {
            return null;
        }

        return isValid(value) ? message : null;
    };
};

export const namesValidator = composeValidators(
    onlyLettersAndDashes(),
    minLength(2, 'Поле должно содержать хотя бы 2 символа'),
    maxLength(25, 'Поле должно содержать менее 25 символов'),
);
