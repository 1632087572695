import classNames from 'classnames';
import React, { useMemo } from 'react';

import { Checkbox, WarningTooltip } from '@components';

const CheckboxInput = ({ label, input, help, meta, tooltip, className, disabled }) => {
    const { checked, onChange, name } = input;
    const { touched, valid, error, submitError } = meta;
    const helpText = touched && (error || submitError) || help;

    const checkbox = useMemo(() => <Checkbox label={label} name={name} onChange={onChange} value={checked} disabled={disabled} />, [label, name, onChange, checked, disabled]);

    return <div className={className} name={name} >
        {
            tooltip ? <WarningTooltip
                arrow={true}
                {...tooltip
                }
            >
                {checkbox}
            </WarningTooltip> : checkbox}
        {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
    </div >;
};

export default CheckboxInput;
