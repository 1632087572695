import React, { useCallback } from 'react';
import { Field, useField } from 'react-final-form';

import { Button } from '@components';
import { SPECIALIST_STATUS } from '@constants';
import { SwitchInput } from '@form-components';
import { useModal } from '@hooks';
import { PlusIcon } from '@images/plus.svg';

import AddSpecialistModal from './add-specialist-modal';

import './specialists-block.scss';

const SpecialistsBlock = ({ specialists, mode }) => {
    const {
        input: { value, onChange },
        meta: { touched, error, submitError },
    } = useField('specialists', { initialValue: specialists });

    const [isOpenedAddSpecialist, handleOpenAddSpecialist, handleCloseSpecialist] = useModal();

    const onDeleteHandler = useCallback(specialistId => {
        onChange(value.filter(({ id }) => id !== specialistId));
    }, [onChange, value]);

    return <div className='create-case-page__form__block specialists-block'>
        <div className='create-case-page__form__block-title'>
            <span className='specialists-block__title'>Рабочая группа</span>
        </div>
        {!!value.length && <div className='create-case-page__form__block-body'>
            {value.map(({
                id,
                avatar,
                last_name,
                first_name,
                middle_name,
                legal_case_category,
                is_user_active,
            }, index) =>
                <div key={id} className='specialists-block__specialist'>
                    <div className='specialists-block__specialist-avatar'>
                        <img src={avatar} />
                    </div>
                    <div className='specialists-block__specialist-info'>
                        <div>{last_name}</div>
                        <div>{first_name} {middle_name}</div>
                        {legal_case_category?.name && <div className='specialists-block__specialist-type'>{legal_case_category.name}</div>}
                    </div>
                    <div className='specialists-block__specialist-delete'>
                        {mode === 'status_editable' &&
                            <div className='specialists-block__specialist-delete_switch'>
                                <Field
                                    name={`specialists[${index}].is_user_active`}
                                    isGrayStyle={true}
                                    component={SwitchInput}
                                    choice={[
                                        { key: SPECIALIST_STATUS.NOT_ACTIVE },
                                        { key: SPECIALIST_STATUS.ACTIVE },
                                    ]}
                                    checked={is_user_active === SPECIALIST_STATUS.ACTIVE ? true : false}
                                />
                            </div>
                        }
                        <Button className='specialists-block__specialist-delete-btn' size='text' onClick={() => onDeleteHandler(id)}>Удалить</Button>
                    </div>
                </div>,
            )}
            {touched && (error || submitError) &&
                <div className='string-input__help string-input__help_warning'>{error || submitError}</div>}
        </div>}

        <Button
            className='specialists-block__specialist-add'
            view='primary-outline'
            size='small'
            type='button'
            onClick={handleOpenAddSpecialist}
            startIcon={<PlusIcon />}
        >
            Добавить участника
        </Button>
        {isOpenedAddSpecialist && <AddSpecialistModal
            onClose={handleCloseSpecialist}
            value={value}
            onChange={onChange}
            isAddedSpecialistsActive={true}
        />}
    </div>;
};

export default SpecialistsBlock;
