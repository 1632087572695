import axios from 'axios';

export const getSiteHeaderData = async () => {
    return await axios.get('/api/site/header/data');
};

export const getSpecialistInfo = async specialistId => {
    return await axios.get(`/api/site/page/specialist/${specialistId}/data`);
};

export const getSpecialists = async queryParams => {
    return await axios.get(`/api/site/page/catalog/specialist/data?${queryParams}`);
};

export const getSpecialistsQuantity = async queryParams => {
    return await axios.get(`/api/site/page/catalog/specialist/quantity?${queryParams}`);
};

export const getMyDocuments = async queryParams => {
    return await axios.get(`/api/legal_case_documents?${queryParams}`);
};
