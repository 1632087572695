export function logoutUser() {
    return async (dispatch, getState) => {
        let token = getState().currentUser.authUserToken;

        await fetch('/api/site/auth/logout', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({}),
        });

        dispatch(logoutUserDataClearAction());
    };
}

export function logoutUserDataClearAction() {
    return async dispatch => {
        if (process.browser) {
            localStorage.clear('authUserHash');
        }
        dispatch(logoutUserAction());
    };
}

export function logoutUserAction() {
    return {
        type: 'USER_LOGOUT',
        payload: null,
    };
}
