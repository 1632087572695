import React from 'react';

import './number-badge.scss';

const NumberBadge = ({ children, className }) => {
    return (
        <div className={`number-badge ${className ? className : ''}`}>{children}</div>
    );
};

export default NumberBadge;