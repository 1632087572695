export function getSpecialistDataAction(data) {
    return {
        type: 'SPECIALIST_STATE',
        payload: data,
    };
}

export function setSpecialistQuantity(data) {
    return {
        type: 'SET_SPECIALIST_QUANTITY',
        payload: data,
    };
}
