import qs from 'qs';
import { getNotificationsSelector } from 'src/selectors/notification-selector';

import { unexpectedExceptionNotification } from '@components';
import axios from '@utils/axios';

const prefix = '@NOTIFICATION';

export const SET_SEARCH = `${prefix}/SET_SEARCH`;
export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;

export const SET_IS_GET_NOTIFICATION_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_NOTIFICATION_REQUEST_PROCESSING`;
export const SET_GET_NOTIFICATION_REQUEST_ERROR = `${prefix}/SET_GET_NOTIFICATION_REQUEST_ERROR`;
export const SET_NOTIFICATION = `${prefix}/SET_NOTIFICATION`;
export const SET_COUNT_NOTIFICATION = `${prefix}/SET_COUNT_NOTIFICATION`;
export const SET_OPEN_NOTIFICATION = `${prefix}/SET_OPEN_NOTIFICATION`;
export const SET_CLOSE_NOTIFICATION = `${prefix}/SET_CLOSE_NOTIFICATION`;
export const SET_PAGINATION_NOTIFICATION = `${prefix}/SET_PAGINATION_NOTIFICATION`;
export const RESET_PAGINATION_NOTIFICATION = `${prefix}/RESET_PAGINATION_NOTIFICATION`;

export const setFiltersAction = filters => ({
    type: SET_SEARCH,
    payload: filters,
});

export const setCurrentPageAction = selectedPage => ({
    type: SET_CURRENT_PAGE,
    payload: selectedPage,
});

export const setIsGetNotificationRequestProcessing = isProcessing => ({
    type: SET_IS_GET_NOTIFICATION_REQUEST_PROCESSING,
    payload: isProcessing,
});

export const setGetNotificationRequestError = error => ({
    type: SET_GET_NOTIFICATION_REQUEST_ERROR,
    payload: error,
});

export const setNotifications = notifications => ({
    type: SET_NOTIFICATION,
    payload: notifications,
});

export const setPaginationNotification = notifications => ({
    type: SET_PAGINATION_NOTIFICATION,
    payload: notifications,
});

export const resetPaginationNotification = () => ({
    type: RESET_PAGINATION_NOTIFICATION,
    payload: [],
});

export const updateNotificationsCounter = count => ({
    type: SET_COUNT_NOTIFICATION,
    payload: count,
});

export const setOpenNotification = () => ({
    type: SET_OPEN_NOTIFICATION,
    payload: true,
});

export const setCloseNotification = () => ({
    type: SET_CLOSE_NOTIFICATION,
    payload: false,
});

export const getNotifications = () => async (dispatch, getState) => {
    dispatch(setIsGetNotificationRequestProcessing(true));
    try {
        const { filters, currentPage } = getNotificationsSelector(getState());

        const queryParams = qs.stringify({ ...filters, page: currentPage + 1 });

        const { data } = await axios.get(`/api/notifications?${queryParams}`);

        dispatch(setNotifications(data));
        dispatch(setPaginationNotification(data?.data));
    } catch (error) {
        dispatch(setGetNotificationRequestError(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetNotificationRequestProcessing(false));
};

export const getCountNotifications = () => async dispatch => {
    dispatch(setIsGetNotificationRequestProcessing(true));
    try {
        const { data } = await axios.get('/api/user/counter/notifications');

        dispatch(updateNotificationsCounter(data?.data?.notifications));
    } catch (error) {
        dispatch(setGetNotificationRequestError(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetNotificationRequestProcessing(false));
};
