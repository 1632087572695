import { format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBlock, DivideLine, MessageControl } from 'src/pages/site/case-page/case-tabs/chats-tabs/common';
import { getPrivateOrderChatSelector } from 'src/selectors/private-order-chat-selector';
import { Slide } from '@material-ui/core';

import { clearReadMessagesQueue, getPrivateOrderChatAction, resetPrivateChat, sendMessage } from '@actions/private-order-chat';
import { Button, Spinner } from '@components';
import { useModal } from '@hooks';
import ArrowIcon from '@images/arrow.svg';
import { CloseIcon } from '@images/close.svg';
import MoreIcon from '@images/more.svg';
import * as R from '@utils/ramda';

import SlideMorePopup from './SlideMorePopup/SlideMorePopup';

import './slide-chat.scss';

const SlideChat = ({ isChatOpened, onClose, chatId, orderId, userId }) => {
    const messagesContainerRef = useRef();
    const dispatch = useDispatch();

    const {
        readMessages,
        unreadMessages,
        total,
        getPrivateOrderChatRequest: { isProcessing },
    } = useSelector(getPrivateOrderChatSelector);

    useEffect(() => {
        if (isChatOpened) {
            document.body.style.overflow = 'hidden';
        }

        return () => (document.body.style.overflow = 'visible');
    }, [isChatOpened]);

    useEffect(() => {
        dispatch(getPrivateOrderChatAction(orderId, chatId));

        return () => {
            clearReadMessagesQueue();
            dispatch(resetPrivateChat());
        };
    }, [orderId, chatId, dispatch]);

    const readMessagesBlocks = useMemo(() => {
        if (!readMessages) {
            return {};
        }

        return R.groupBy(({ created }) => new Date(created).toLocaleDateString())(readMessages);
    }, [readMessages]);

    const scrollToBottom = () => {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    });

    const onMessageSubmitHandler = useCallback(
        message => {
            dispatch(sendMessage(orderId, chatId, message));
        },
        [dispatch, chatId, orderId],
    );

    const [isMoreOpened, handleMoreOpen, handleMoreClose] = useModal();

    return (
        <Slide direction='left' in={isChatOpened}>
            <div className='slide-chat__overlay' onClick={onClose}>
                <div className='slide-chat' onClick={e => e.stopPropagation()}>
                    <Button className='slide-chat__button' onClick={onClose} startIcon={<CloseIcon />} />
                    <div className='slide-chat__header_mobile'>
                        <ArrowIcon onClick={onClose} />
                        <h3>Мой профиль</h3>
                    </div>
                    <div className='slide-chat__header'>
                        <div className='slide-chat__header-title'>
                            <h5 className='slide-chat__title'>Чат по заявке</h5>
                            <p className='slide-chat__total'>{total}</p>
                        </div>
                        <MoreIcon className='slide-chat__more' onClick={handleMoreOpen} />
                        <SlideMorePopup isOpened={isMoreOpened} onClose={handleMoreClose} chatId={chatId} orderId={orderId} userId={userId} />
                    </div>
                    <div className='slide-chat__content'>
                        <div className='slide-chat__messages-container' ref={messagesContainerRef}>
                            {Object.keys(readMessagesBlocks).length === 0 && <p className='slide-chat__empty-chat'>Здесь пока нет сообщений</p>}
                            {Object.keys(readMessagesBlocks).length !== 0 &&
                                Object.keys(readMessagesBlocks).map(date =>
                                    <Fragment key={date}>
                                        <DivideLine label={format(parse(date, 'dd.MM.yyyy', new Date()), 'd MMMM', { locale: ru })} />
                                        <ChatBlock messageData={readMessagesBlocks[date]} isRead={true} isGeneralChat={false} isOrder={true} />
                                    </Fragment>,
                                )}

                            {unreadMessages && unreadMessages.length !== 0 &&
                                <>
                                    <DivideLine label='Новые сообщения' />
                                    <ChatBlock messageData={unreadMessages} isRead={false} isGeneralChat={false} isOrder={true} />
                                </>
                            }
                        </div>
                        <div>
                            <MessageControl onSubmit={onMessageSubmitHandler} />
                        </div>
                        {isProcessing && <Spinner mode='over' />}
                    </div>
                </div>
            </div>
        </Slide>
    );
};

export default SlideChat;
