import axios from 'axios';

export const getCreatePlanEventForm = async caseId => {
    const { data } = await axios.get(`/api/legal_cases/${caseId}/plan_events/create`);

    return data;
};

export const createPlanEvent = (caseId, event) => {
    return axios.post(`/api/legal_cases/${caseId}/plan_events`, event);
};

export const getEditPlanEventForm = async (caseId, eventId) => {
    const { data } = await axios.get(`/api/legal_cases/${caseId}/plan_events/${eventId}/edit`);

    return data;
};

export const updatePlanEvent = (caseId, eventId, event) => {
    return axios.put(`/api/legal_cases/${caseId}/plan_events/${eventId}`, event);
};
