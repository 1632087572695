import React, { useEffect, useRef } from 'react';

import { useAdaptive } from '@hooks';

import './modal.scss';

const Modal = ({ title, open, onClose, children, className, isOverlay = true, isHavePopup = false }) => {
    const { isMobile, isTablet } = useAdaptive();
    const isMobileOrTabletRef = useRef();

    useEffect(() => { isMobileOrTabletRef.current = isMobile || isTablet; }, [isMobile, isTablet]);

    const openRef = useRef(false);

    useEffect(() => {
        if (open || isHavePopup) {
            document.body.style.overflow = 'hidden';
        } else if (openRef.current && !isMobileOrTabletRef.current) {
            document.body.style.overflow = 'auto';
        }

        openRef.current = open;

        return () => {
            if (openRef.current && !isMobileOrTabletRef.current) {
                document.body.style.overflow = 'auto';
            }
        };
    }, [isHavePopup, open]);

    if (!open) {
        return null;
    }

    return <div className={`${isOverlay ? 'modal-container__overlay' : 'modal-container'}`} onClick={onClose}>
        <div className={`modal ${className}`} onClick={e => e.stopPropagation()}>
            {title && <div className='modal__title'>
                {title}
            </div>}
            <div className='modal__body'>
                {children}
            </div>
        </div>
    </div>;
};

export default Modal;
