import PropTypes from 'prop-types';
import React from 'react';

import AttentionUnionSvg from '@images/attention-union.svg';

import './attention.scss';

const Attention = ({ className, title, descr, children }) => {
    return (
        <div className={`attention ${className}`}>
            <div className='attention-icon'>
                <AttentionUnionSvg />
            </div>
            <div className='attention__text'>
                {title && <h3 className='attention__text-title'>
                    {title}
                </h3>}
                <p className='attention__text-descr'>
                    {descr}
                </p>
                {children}
            </div>
        </div>
    );
};

Attention.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    descr: PropTypes.string,
    children: PropTypes.node,
};

export default Attention;