import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import MoreIcon from '@images/more-vert.svg';

import './icons-menu.scss';

const chunk = (arr, len) => {
    var chunks = [],
        i = 0,
        n = arr.length;

    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }

    return chunks;
};

const IconsMenu = ({ active, onSetActive, icons: allIcons, countIconsInRow }) => {
    const [open, setOpen] = useState();
    const toggleOpen = useCallback(() => setOpen(!open), [open]);

    const rows = useMemo(() => {
        const chunks = chunk(allIcons, countIconsInRow);

        if (chunks.length > 1) {
            const lastChunk = chunks[chunks.length - 1];

            if (lastChunk.length !== countIconsInRow) {
                for (let i = 0; i <= countIconsInRow - lastChunk.length; i++) {
                    lastChunk.push({ isEmpty: true, counts: {} });
                }
            }
        }

        return chunks;
    }, [allIcons, countIconsInRow]);

    return <div className={classNames('icons-menu', { 'icons-menu_multi-rows': rows.length > 1 })}>
        {rows.filter((_, index) => open || !open && index === 0).map((icons, index) =>
            <div className='icons-menu__row' key={index}>
                {icons.map(({ isEmpty, key, label, icon, counts: { total, new: newCount }, paintType }, index) => {
                    if (isEmpty) {
                        return <button key={index} className='icons-menu__icon icons-menu__icon_empty' />;
                    }

                    return <button
                        className={classNames('icons-menu__icon',
                            {
                                'icons-menu__icon_active': key === active,
                                'icons-menu__icon_stroke-active': key === active && paintType === 'stroke',
                                'icons-menu__icon_fill-active': key === active && paintType === 'fill',
                            })}
                        key={key}
                        type='button'
                        onClick={() => onSetActive(key)}
                    >
                        {icon}
                        <span>{label}</span>
                        <div className='icons-menu__icon-badge'>
                            {total !== undefined && <span className='icons-menu__icon-badge-total'>{total}</span>}
                            {newCount !== undefined && newCount !== 0 && <span className='icons-menu__icon-badge-new'>+{newCount}</span>}
                        </div>
                    </button>;
                })}
            </div>,
        )}

        {rows.length > 1 && <button
            className={classNames('icons-menu__control', { 'icons-menu__control_open': open })}
            onClick={toggleOpen}
            type='button'
        >
            <MoreIcon />
        </button>}
    </div >;
};

export default IconsMenu;
