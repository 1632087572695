import React, { useCallback, useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';

import { findIndex } from '@utils/ramda';

import { NumberBadge } from '../number-badge';

import './tabs.scss';

const Tabs = ({ tabs, selected, onSelect }) => {
    const selectedIndex = useMemo(() => findIndex(({ key }) => key === selected)(tabs), [selected, tabs]);
    const onSelectHandler = useCallback(index => onSelect(tabs[index].key), [onSelect, tabs]);

    return (
        <div className='tabs'>
            <ReactTabs selectedIndex={selectedIndex} onSelect={onSelectHandler}>
                <TabList className='tabs__list'>
                    {tabs.map(({ key, label, counts }) => <Tab key={key} className='tabs__tab'>
                        {label}
                        {counts?.total !== undefined && counts?.total !== 0 && <div className='tabs__tab-notice'>{counts?.total}</div>}
                        {counts?.new !== undefined && counts?.new !== 0 && <NumberBadge>{counts?.new}</NumberBadge>}
                    </Tab>)}
                </TabList>

                {tabs.map(tab => <TabPanel key={tab.key}>
                    {tab.content}
                </TabPanel>)}
            </ReactTabs>
        </div>
    );
};

export default Tabs;
