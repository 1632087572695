import React from 'react';
import { Field, Form } from 'react-final-form';

import { FormValuesSpy } from '@components';
import { StringInput } from '@form-components';
import MagnifierIcon from '@images/magnifier.svg';

const SearchInput = ({ placeholder, onChange, secondary, value }) => {
    return <Form initialValues={{
        search: value,
    }} onSubmit={onChange} render={({ handleSubmit }) =>
        <>
            <FormValuesSpy onChange={onChange} debounceTime={200} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete='off'>
                <Field
                    name='search'
                    component={StringInput}
                    type='text'
                    secondary={secondary}
                    placeholder={placeholder}
                    rightIcon={<MagnifierIcon />}
                />
            </form>
        </>
    } />;
};

export default SearchInput;
