import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalError } from 'src/selectors/global-error-selector';

import { ErrorBlock404, ErrorBlock503 } from './blocks';

const GlobalErrorBlock = () => {
    const { code, info } = useSelector(getGlobalError);

    if (code === 404) {
        return <ErrorBlock404 />;
    }

    return <ErrorBlock503 info={info} />;
};

export default GlobalErrorBlock;
