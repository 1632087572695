import axios from 'axios';
import dynamic from 'next/dynamic';
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Modal } from '@material-ui/core';

import { notificationStatuses, svgStarPath } from '@constants';
import { TextAreaInput } from '@form-components';
import { CloseIcon } from '@images/close.svg';
import { RefreshIcon } from '@images/refresh.svg';
import { UserIcon } from '@images/user.svg';

import { Button } from '../button';
import { createNotification, unexpectedExceptionNotification } from '../notification';

import './review-popup.scss';

const StarRatings = dynamic(() => import('react-star-ratings'), { ssr: false });

const ReviewPopup = ({ isOpened, onClose, specialistData, refetch = () => { } }) => {
    const [rating, setRating] = useState(0);

    const handleChangeRating = rating => setRating(rating);

    const onSubmit = useCallback(async values => {
        try {
            await axios.post(`/api/reviews/${specialistData?.data?.public_id}/store`, {
                description: values.description,
                rating: rating,
            });
            onClose();
            setRating(0);
            refetch();
            createNotification('Отзыв создан успешно', notificationStatuses.success);
        } catch (err) {
            unexpectedExceptionNotification();
        }
    }, [specialistData, rating, onClose, refetch]);

    return (
        <Modal open={isOpened} onClose={onClose}>
            <div className='review-popup'>
                <div className='review-popup__header'>
                    <h3 className='review-popup__title'>
                        Оцените исполнителя
                    </h3>
                    <CloseIcon className='review-popup__close' onClick={onClose} />
                </div>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => <form onSubmit={handleSubmit}>
                        <div className='review-popup__user'>
                            <img className='review-popup__user-avatar' src={specialistData?.data?.avatar} alt='avatar' />
                            <p className='review-popup__user-name'>
                                {`${specialistData?.data?.first_name} ${specialistData?.data?.middle_name ?? ''} ${specialistData?.data?.last_name}`}
                            </p>
                            <div className='review-popup__user-specialization'>
                                <UserIcon />
                                <span>{specialistData?.data?.specialist_data?.category_specialist?.label}</span>
                            </div>
                            <div>
                                <StarRatings
                                    numberOfStars={5}
                                    rating={rating}
                                    changeRating={handleChangeRating}
                                    starSpacing='8px'
                                    starEmptyColor='#DCDEE4'
                                    starRatedColor='#F8E5B8'
                                    starHoverColor='#F8E5B8'
                                    svgIconPath={svgStarPath}
                                    isAggregateRating={true}
                                />
                            </div>
                            <Field
                                label='Комментарий'
                                name='description'
                                type='text'
                                component={TextAreaInput}
                                secondary={true}
                            />
                            <div className='review-popup__actions'>
                                <Button
                                    view='primary-outline'
                                    size='medium'
                                    startIcon={<RefreshIcon />}
                                    onClick={onClose}
                                >
                                    Отменить
                                </Button>
                                <Button
                                    size='medium'
                                >
                                    Отправить
                                </Button>
                            </div>
                        </div>
                    </form>}
                />
            </div>
        </Modal>
    );
};

export default ReviewPopup;