import classNames from 'classnames';
import { ru } from 'date-fns/locale';
import React, { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import CalendarIcon from '@images/calendar.svg';

import './date-picker-input.scss';

const DatePickerInput = ({ label, input, meta, help, required, placeholder, className, secondary, disableFuture }) => {
    const { value, onFocus, onChange, onBlur, name } = input;
    const { touched, valid, active, error, submitError } = meta;

    const [calendarActive, setCalendarActive] = useState(false);

    const helpText = (touched && (error || submitError)) || help;

    return (
        <div
            className={classNames('date-picker-input', className, {
                'date-picker-input_focused': active || calendarActive,
                'date-picker-input_filled': !!value,
                'date-picker-input_error': touched && !valid,
                'date-picker-input_secondary': secondary,
            })}
        >
            <label htmlFor={name} className='string-input__label'>
                {label}
            </label>
            <div
                className={classNames('string-input__input-wrap', {
                    'string-input__input--necessarily': required,
                })}
            >
                <MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        id={name}
                        name={name}
                        disableToolbar={true}
                        variant='inline'
                        format='dd.MM.yyyy'
                        margin='normal'
                        minDateMessage={null}
                        disableFuture={disableFuture}
                        value={value || null}
                        onBlur={onBlur}
                        autoOk={true}
                        onFocus={onFocus}
                        onChange={onChange}
                        PopoverProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                        keyboardIcon={
                            <div
                                className={classNames('date-picker-input__calendar-button', {
                                    'date-picker-input__calendar-button_active': active || calendarActive,
                                })}
                            >
                                <CalendarIcon />
                            </div>
                        }
                        onOpen={() => setCalendarActive(true)}
                        onClose={() => setCalendarActive(false)}
                        placeholder={placeholder ? placeholder : '__.__.____'}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
        </div>
    );
};

export default DatePickerInput;
