import Link from 'next/link';
import React from 'react';

import { Button } from '@components';

import './error-block-404.scss';

const ErrorBlock404 = () => {
    return <div className='error-block-404'>
        <div className='container'>
            <div className='error-block-404__inner'>
                <h1>Страница не найдена</h1>
                <h4>Проверьте, правильно ли вы ввели адрес</h4>
                <Link href='/site/main-page' as='/'>
                    <Button>На главную</Button>
                </Link>
            </div>
        </div>
    </div>;
};

export default ErrorBlock404;
