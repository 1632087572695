import { logoutUserDataClearAction } from './logoutUser';

export function updateCurrentUser() {
    return async (dispatch, getState) => {
        let token = getState().currentUser.authUserToken;

        let response = await fetch('/api/site/auth/current_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({}),
        });

        let responseJSON = await response.json();

        switch (response.status) {
            case 200:
                dispatch(updateCurrentUserAction({ ...responseJSON, authUserToken: token }));
                break;
            default:
                dispatch(logoutUserDataClearAction());
        }
    };
}

export function updateCurrentUserAction(data) {
    return {
        type: 'USER_UPDATE_DATA',
        payload: data,
    };
}

export function setUnreadMessagesCount(count) {
    return {
        type: 'SET_UNREAD_MESSAGES_COUNT',
        payload: count,
    };
}
