import escapeHtml from 'escape-html';
import isUrl from 'is-url';
import React, { useCallback, useMemo } from 'react';
import {
    createEditor,
    Editor,
    Element as SlateElement,
    Range,
    Text,
    Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import { Editable, Slate, useSlate, withReact } from 'slate-react';
import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, Link, LinkOff } from '@material-ui/icons';

import { Button, Icon, Toolbar } from './components';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const serializeRichText = node => {
    if (Array.isArray(node)) {
        return node.map(n => serializeRichText(n)).join('');
    }

    if (Text.isText(node)) {
        let string = escapeHtml(node.text);

        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }

        return string;
    }

    const children = node.children.map(n => serializeRichText(n)).join('');

    switch (node.type) {
        case 'block-quote':
            return `<blockquote><p>${children}</p></blockquote>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        default:
            return children;
    }
};

export const deserializeRichText = plainRichText => {
    const document = new DOMParser().parseFromString(plainRichText, 'text/html');

    const deserialize = el => {
        if (Array.isArray(el)) {
            return el.map(n => serializeRichText(n));
        }

        if (el.nodeType === 3) {
            // return {
            //     type: 'paragraph',
            //     children: [{ text: el.textContent }],
            // };
            return el.textContent;
        } else if (el.nodeType !== 1) {
            return null;
        }

        let children = Array.from(el.childNodes).map(deserialize);

        if (children.length === 0) {
            children = [{ text: '' }];
        }

        switch (el.nodeName) {
            case 'BODY':
                return jsx('fragment', {}, children);
            case 'BR':
                return '\n';
            case 'BLOCKQUOTE':
                return jsx('element', { type: 'block-quote' }, children);
            case 'P':
                return jsx('element', { type: 'paragraph' }, children);
            case 'A':
                return jsx(
                    'element',
                    { type: 'link', url: el.getAttribute('href') },
                    children,
                );
            case 'UL':
                return jsx('element', { type: 'bulleted-list' }, children);
            case 'OL':
                return jsx('element', { type: 'numbered-list' }, children);
            case 'LI':
                return jsx('element', { type: 'list-item' }, children);
            case 'STRONG':
                return jsx('text', { type: 'span', bold: true }, children);
            case 'EM':
                return jsx('text', { type: 'span', italic: true }, children);
            case 'U':
                return jsx('text', { type: 'span', underline: true }, children);
            default:
                return el.textContent;
        }
    };

    return deserialize(document.body);
};

const RichTextExample = props => {
    const { label, input, placeholder, autoFocus } = props;
    const { onChange, onBlur, onFocus, value, name } = input;

    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []);

    return (
        <>
            {label && <label htmlFor={name} className='form__label'>
                {label}
            </label>}
            <Slate editor={editor} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus}>
                <Toolbar>
                    <MarkButton format='bold' icon={<FormatBold />} />
                    <MarkButton format='italic' icon={<FormatItalic />} />
                    <MarkButton format='underline' icon={<FormatUnderlined />} />
                    {/* <MarkButton format='code' icon={<Code />} />
                    <BlockButton format='heading-one' icon={<LooksOne />} />
                    <BlockButton format='heading-two' icon={<LooksTwo />} /> */}
                    <BlockButton format='block-quote' icon={<FormatQuote />} />
                    <BlockButton format='numbered-list' icon={<FormatListNumbered />} />
                    <BlockButton format='bulleted-list' icon={<FormatListBulleted />} />
                    <LinkButton />
                    <RemoveLinkButton />
                </Toolbar>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={placeholder}
                    spellCheck={true}
                    autoFocus={autoFocus}
                />
            </Slate>
        </>
    );
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
            ),
        split: true,
    });
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };

        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'link':
            return (
                <a {...attributes} href={element.url}>
                    {children}
                </a>
            );
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();

    return (
        <Button
            view='primary-outline'
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();

    return (
        <Button
            view='primary-outline'
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

const withLinks = editor => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const text = data.getData('text/plain');

        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};

const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });

    return !!link;
};

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
};

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

const LinkButton = () => {
    const editor = useSlate();

    return (
        <Button
            view='primary-outline'
            active={isLinkActive(editor)}
            onMouseDown={event => {
                event.preventDefault();
                const url = window.prompt('Enter the URL of the link:');

                if (!url) return;
                insertLink(editor, url);
            }}
        >
            <Icon>
                <Link />
            </Icon>
        </Button>
    );
};

const RemoveLinkButton = () => {
    const editor = useSlate();

    return (
        <Button
            view='primary-outline'
            active={isLinkActive(editor)}
            onMouseDown={() => {
                if (isLinkActive(editor)) {
                    unwrapLink(editor);
                }
            }}
        >
            <Icon>
                <LinkOff />
            </Icon>
        </Button>
    );
};

export default RichTextExample;
