import axios from '@utils/axios';

const putEditFormCase = async (caseId, formData) => {
    const requestData = {
        legal_case: {
            name: formData.legal_case.name,
            description: formData.legal_case.description,
            status: formData.legal_case.status.value,
        },
        documents: formData.documents,
        specialists: formData.specialists,
    };

    return await axios.put(`/api/legal_cases/${caseId}`, requestData);
};

export default putEditFormCase;
