import classNames from 'classnames';
import React, { useState } from 'react';

import { CustomSelect } from '@components';

import './select-input.scss';

const SelectInput = ({
    label,
    input,
    meta,
    help,
    choice,
    required,
    primary,
    onMenuScrollToBottom,
    isMulti,
    placeholder = '- выберите -',
    secondary,
    isSearchable = false,
    isDisabled,
    className,
    dropdownIndicator,
}) => {
    const { value, onChange, onFocus, onBlur, name } = input;
    const { touched, valid, error, submitError } = meta;

    const helpText = (touched && (error || submitError)) || help;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className={`select-input ${className ? className : ''}`}>
            {!!label && <label htmlFor={name} className='form__label'>
                {label}
            </label>}

            <div
                className={classNames('select-input__input-wrap', {
                    'select-input__input--primary': primary,
                    'select-input__input--necessarily': required,
                    'select-input__input--filled': !!value,
                    'select-input__input--menu-open': isMenuOpen,
                    'select-input__input--done': touched && valid,
                    'select-input__input--error': touched && !valid,
                    'select-input__input-isMulti': isMulti,
                    'select-input__input--secondary': secondary,
                    'select-input__input--isDisabled': isDisabled,
                })}
            >
                <CustomSelect
                    id={name}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    options={choice}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onMenuScrollToBottom={onMenuScrollToBottom}
                    dropdownIndicator={dropdownIndicator}
                    onMenuOpen={() => setIsMenuOpen(true)}
                    onMenuClose={() => setIsMenuOpen(false)}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    isDisabled={isDisabled}
                />
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
        </div >
    );
};

export default SelectInput;
