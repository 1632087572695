import React, { useState } from 'react';
import { useCallback } from 'react';

import EyeCloseIcon from '@images/eye-close.svg';
import EyeOpenIcon from '@images/eye-open.svg';

import { StringInput } from '../string-input';

import './password-input.scss';

const PasswordInput = props => {
    const [type, setType] = useState('password');

    const changeTypeHandler = useCallback(() => {
        setType(type === 'password' ? 'text' : 'password');
    }, [type]);

    props.input.type = type;

    return <StringInput
        {...props}
        rightIcon={
            <div className='password-input__right-icon' onClick={changeTypeHandler}>
                {type === 'password' ? <EyeCloseIcon /> : <EyeOpenIcon />}
            </div>
        }
    />;
};

export default PasswordInput;
