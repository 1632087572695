import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './PersonalHeader.scss';
import '../site-header/Header.scss';

class PersonalHeader extends Component {
    render() {
        const { currentUser } = this.props;

        return (
            <header className='header'>
                <div className='container'>
                    <div className='header__inner'>
                        <div className='header__logo-wrap'>
                            <Link to='/' className='header__logo'>
                                <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'> <g clipPath='url(#clip0)'> <path d='M20.0761 0C20.0761 0 12.9137 28.5823 12.457 30.4762C11.5437 34.4156 12.457 38.0952 16.2665 40C10.4059 40 -1.90479 40 -1.90479 40L-1.90479 0H20.0761Z' fill='#CFE2F3' /> <path fillRule='evenodd' clipRule='evenodd' d='M39.9999 0L21.9047 2.04359e-06V26.6667C18.2343 26.6667 15.238 29.6629 15.238 33.3333C15.238 37.0037 18.2343 40 21.9047 40H39.9999V0Z' fill='#193B7D' /> </g> <defs> <clipPath id='clip0'> <rect width='40' height='40' fill='white' /> </clipPath> </defs> </svg>
                                <span>Юридический сервис</span>
                            </Link>
                        </div>

                        <nav className='header__nav'>
                            <ul className='header__nav-list'>
                                <li className='header__nav-item'>
                                    <Link to='/specialists'>Адвокаты</Link>
                                </li>
                                <li className='header__nav-item'>
                                    <Link to='/specialists'>Нотариусы</Link>
                                </li>
                                <li className='header__nav-item'>
                                    <Link to='/specialists'>Патентный поверенный</Link>
                                </li>
                                <li className='header__nav-item'>
                                    <Link to='/specialists'>Переводчики</Link>
                                </li>
                                <li className='header__nav-item'>
                                    <Link to='/specialists'>Судебные эксперты</Link>
                                </li>
                            </ul>
                        </nav>

                        <div className='header__account'>
                            <div className='header-personal__wrap'>
                                <div className='header-personal__avatar'>
                                    <img src='https://i.ibb.co/7JKcLpr/header-avatar.png' alt='' />
                                    {currentUser.unreadMessagesCount !== 0 && <div className='header-personal__notice'>
                                        <span>{currentUser.unreadMessagesCount}</span>
                                    </div>}
                                </div>
                                <div className='header-personal__name-wrap'>
                                    <div className='header-personal__surname'>{this.props.basicData['last_name']}</div>
                                    <div className='header-personal__name'>{this.props.basicData['first_name']}</div>
                                </div>
                                <button className='header-personal__btn'></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default connect(
    state => ({
        currentUser: state.currentUser,
    }),
)(PersonalHeader);
