import Link from 'next/link';
import React, { useCallback } from 'react';

import { Button } from '@components';

import './error-block-503.scss';

const ErrorBlock503 = ({ info }) => {
    const onReloadHandler = useCallback(() => document.location.reload(), []);

    return <div className='error-block-503'>
        <div className='container '>
            <div className='error-block-503__inner'>
                <h1>Страница не доступна</h1>
                <h4>{info}</h4>
                <Button view='primary-outline' onClick={onReloadHandler}>Обновить</Button>
                <Link href='/site/main-page' as='/'>
                    <Button>На главную</Button>
                </Link>
            </div>
        </div>
    </div>;
};

export default ErrorBlock503;
