import { add, addDays, differenceInDays, endOfWeek, format, isAfter, isEqual, parse, setHours, setMinutes, startOfWeek, sub } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ru } from 'date-fns/locale';
import React, { useCallback, useMemo } from 'react';

import ArrowIcon from '@images/arrow.svg';

import Cell from './Cell/Cell';

import './calendar.scss';

const Calendar = ({ activeDate, currentWeek, setCurrentWeek, timeZone = 'Europe/Kaliningrad', testSchedule, handleClickTime }) => {
    const startDate = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const endDate = endOfWeek(currentWeek, { weekStartsOn: 1 });
    const formattedStartDate = format(startDate, 'd MMMM yyyy', { locale: ru });
    const formattedEndDate = format(endDate, 'd MMMM yyyy', { locale: ru });
    const numDays = differenceInDays(endDate, startDate) + 1;
    const maxEndTime = Math.max(...testSchedule.schedule.map(day => day.endTime || 0));
    const maxStartTime = Math.min(...testSchedule.schedule.map(day => day.startTime || 24));
    const CanGoPrev = useMemo(() => isAfter(sub(currentWeek, { weeks: 1 }), startOfWeek(new Date())), [currentWeek]);

    const prevWeek = useCallback(() => {
        if (CanGoPrev) {
            setCurrentWeek(sub(currentWeek, { weeks: 1 }));
        }
    }, [CanGoPrev, currentWeek, setCurrentWeek]);

    const nextWeek = useCallback(() => setCurrentWeek(add(currentWeek, { weeks: 1 })), [currentWeek, setCurrentWeek]);

    return (
        <div className='calendar'>
            <div className='calendar__control'>
                <div onClick={prevWeek} className={`calendar__control-left ${!CanGoPrev && 'calendar__control-disabled'}`}>
                    <ArrowIcon />
                </div>
                <p className='calendar__date'>{`${formattedStartDate} - ${formattedEndDate}`}</p>
                <div onClick={nextWeek} className='calendar__control-right'>
                    <ArrowIcon />
                </div>
            </div>
            <div className='calendar__body'>
                {Array.from({ length: numDays }).map((_, index) => {
                    const date = addDays(startDate, index);
                    const formattedDate = format(date, 'd MMMM', { locale: ru });

                    return (
                        <div key={date} className='calendar__column'>
                            <Cell value={`${testSchedule.schedule[index].dayOfWeek} ${formattedDate}`} isHeaderCell={true} />
                            {Array.from({
                                length: ((maxEndTime - maxStartTime + 1) * 60) / testSchedule.timeStep,
                            }).map((_, timeIndex) => {
                                const hour = Math.floor((timeIndex * testSchedule.timeStep) / 60) + maxStartTime;
                                const minute = (timeIndex * testSchedule.timeStep) % 60;
                                const dateTime = setHours(setMinutes(date, minute), hour);
                                const utcDateTime = zonedTimeToUtc(dateTime, testSchedule.timeZone);
                                const zonedDateTime = utcToZonedTime(utcDateTime, timeZone);

                                if (
                                    testSchedule.schedule[index].isWeekend ||
                                    hour < testSchedule.schedule[index].startTime ||
                                    hour > testSchedule.schedule[index].endTime
                                ) {
                                    return (
                                        <div key={zonedDateTime}>
                                            <Cell value='-' isZoned={true} />
                                        </div>);
                                } else {
                                    const isTaken = testSchedule.schedule[index]?.busySlots?.some(slot => {
                                        const slotStart = parse(slot.start, 'HH:mm', new Date());

                                        return format(zonedDateTime, 'HH:mm') === format(slotStart, 'HH:mm');
                                    });

                                    return (
                                        <div key={zonedDateTime}>
                                            <Cell
                                                value={format(zonedDateTime, 'HH:mm')}
                                                isActive={isEqual(activeDate, zonedDateTime)}
                                                onClick={isEqual(activeDate, zonedDateTime) ? () => { } : () => handleClickTime(zonedDateTime, isTaken)}
                                                isTaken={isTaken}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Calendar;
