import React from 'react';

import './title-tabs.scss';

const TabItem = ({ name, activeTab, onClick }) =>
    <li
        className={`title-tabs-item ${activeTab === name ? 'title-tabs-item_active' : ''}`}
        onClick={() => onClick(name)}
    >
        {name}
    </li>;

const TitleTabs = ({ tabs, activeTab, onClickTab }) => {
    return (
        <ul className='title-tabs'>
            {tabs.map(tab =>
                <TabItem key={tab} name={tab} activeTab={activeTab} onClick={onClickTab} />,
            )}
        </ul>
    );
};

export default TitleTabs;