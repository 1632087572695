import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { unexpectedExceptionNotification } from '@components';

export const useAxios = (apiUrl, params, conditions = true) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(apiUrl, { params });

            setData(response.data);
            setError(null);
        } catch (err) {
            setError(err);
            unexpectedExceptionNotification();
        }
        setIsLoading(false);
    }, [apiUrl, params]);

    useEffect(() => {
        if (conditions) {
            fetchData();
        }
    }, [conditions, fetchData]);

    return [data, isLoading, error, fetchData];
};
