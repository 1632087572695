import { createAction } from 'redux-actions';

import { setGlobalError } from '@actions/global-error';
import { unexpectedExceptionNotification } from '@components';
import { getCasePageSelector } from '@selectors/case-page-selector';
import axios from '@utils/axios';

const prefix = '@CASE_PAGE';

const createActionWithPrefix = (name, payloadCreator, metaCreator) => createAction(`${prefix}/${name}`, payloadCreator, metaCreator);

export const setSelectedCaseId = createActionWithPrefix('SET_SELECTED_CASE_ID');
export const setSelectedTabName = createActionWithPrefix('SET_SELECTED_TAB_NAME');
export const setIsShouldScroll = createActionWithPrefix('SET_IS_SHOULD_SCROLL');
export const setIsGetCaseInfoRequestProcessing = createActionWithPrefix('SET_IS_GET_CASE_INFO_REQUEST_PROCESSING');
export const setGetCaseInfoRequestError = createActionWithPrefix('SET_GET_CASE_INFO_REQUEST_ERROR');
export const setCaseInfo = createActionWithPrefix('SET_CASE_INFO');
export const setIsGetWorkingGroupRequestProcessing = createActionWithPrefix('SET_IS_GET_WORKING_GROUP_REQUEST_PROCESSING');
export const setGetWorkingGroupRequestError = createActionWithPrefix('SET_GET_WORKING_GROUP_REQUEST_ERROR');
export const setWorkingGroup = createActionWithPrefix('SET_WORKING_GROUP');
export const addUnreadMessageToCount = createActionWithPrefix('ADD_UNREAD_MESSAGE_TO_COUNT', (currentUserId, message) => ({ currentUserId, message }));
export const setIsGetEditCaseFormRequestProcessing = createActionWithPrefix('SET_IS_GET_EDIT_CASE_FORM_REQUEST_PROCESSING');
export const setGetEditCaseFormRequestError = createActionWithPrefix('SET_GET_EDIT_CASE_FORM_REQUEST_ERROR');
export const setEditCaseForm = createActionWithPrefix('SET_EDIT_CASE_FORM');

export const setMessagesCounts = createActionWithPrefix(
    'SET_MESSAGES_COUNTS',
    (numberOfMessagesInGeneralChat, numberOfMessagesInPrivateChats, numberOfUnreadMessagesInGeneralChat, numberOfUnreadMessagesInPrivateChats) => ({
        numberOfMessagesInGeneralChat,
        numberOfMessagesInPrivateChats,
        numberOfUnreadMessagesInGeneralChat,
        numberOfUnreadMessagesInPrivateChats,
    }),
);

export const removeUnreadMessagesFromCount = createActionWithPrefix('REMOVE_UNREAD_MESSAGES_FROM_COUNT', (chatId, readMessagesCount) => ({
    chatId,
    readMessagesCount,
}));

export const getCaseInfo = () => async (dispatch, getState) => {
    dispatch(setIsGetCaseInfoRequestProcessing(true));
    try {
        const { caseId } = getCasePageSelector(getState());
        const { data } = await axios.get(`/api/legal_cases/${caseId}`);

        dispatch(setCaseInfo(data));
    } catch (error) {
        if (error.response?.status) {
            dispatch(setGlobalError(error.response?.status));
        }
        dispatch(setGetCaseInfoRequestError(error));
    }
    dispatch(setIsGetCaseInfoRequestProcessing(false));
};

export const getWorkingGroup = () => async (dispatch, getState) => {
    dispatch(setIsGetWorkingGroupRequestProcessing(true));
    try {
        const { caseId } = getCasePageSelector(getState());
        const { data } = await axios.get(`/api/legal_cases/${caseId}/working_group`);
        const { creator, specialists } = data;

        creator.is_user_active = true;

        dispatch(setWorkingGroup([creator, ...specialists]));
    } catch (error) {
        dispatch(setGetWorkingGroupRequestError(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetWorkingGroupRequestProcessing(false));
};

export const getEditCaseForm = () => async (dispatch, getState) => {
    dispatch(setIsGetEditCaseFormRequestProcessing(true));
    try {
        const { caseId } = getCasePageSelector(getState());
        const { data } = await axios.get(`/api/legal_cases/${caseId}/edit`);

        dispatch(setEditCaseForm(data));
    } catch (error) {
        dispatch(setGetEditCaseFormRequestError(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetEditCaseFormRequestProcessing(false));
};
