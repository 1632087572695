import axios from 'axios';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { resetPrivateChat } from '@actions/private-order-chat';
import { notificationStatuses } from '@constants';
import { TextAreaInput } from '@form-components';
import { useModal } from '@hooks';
import BlockIcon from '@images/block.svg';
import ComplainIcon from '@images/complain.svg';
import MoreIcon from '@images/more.svg';
import TrashIcon from '@images/trash.svg';
import { required } from '@validators';

import { Button } from '../../button';
import { Modal } from '../../modal';
import { createNotification, unexpectedExceptionNotification } from '../../notification';

import './slide-more-popup.scss';

const SlideMorePopup = ({ isOpened, onClose, chatId, orderId, userId }) => {
    const [isComplainModal, handleComplainOpen, handleComplainClose] = useModal();
    const dispatch = useDispatch();

    const handleComplainUser = useCallback(async description => {
        try {
            await axios.post(`/api/orders/${orderId}/chats/${chatId}/report/${userId}`, { description: description });
            handleComplainClose();
            onClose();
        } catch (error) {
            if (error?.response?.status) {
                unexpectedExceptionNotification();
            }
        }
    }, [chatId, handleComplainClose, onClose, orderId, userId]);

    const handleBlockUser = useCallback(async () => {
        try {
            await axios.post(`/api/orders/${orderId}/chats/${chatId}/block/${userId}`);
            onClose();
        } catch (err) {
            if (err.response?.status === 422) {
                err.response.data.errors?.map(error => createNotification(error, notificationStatuses.error));
            } else if (err.response?.status === 403) {
                createNotification('Доступ запрещён', notificationStatuses.error);
            } else {
                unexpectedExceptionNotification();
            }
        }
    }, [chatId, onClose, orderId, userId]);

    const handleClearChat = useCallback(async () => {
        try {
            await axios.post(`/api/orders/${orderId}/chats/${chatId}/clear`);
            dispatch(resetPrivateChat());
            onClose();
        } catch (err) {
            if (err.response?.status === 422) {
                err.response.data.errors?.map(error => createNotification(error, notificationStatuses.error));
            } else if (err.response?.status === 403) {
                createNotification('Доступ запрещён', notificationStatuses.error);
            } else {
                unexpectedExceptionNotification();
            }
        }
    }, [chatId, dispatch, onClose, orderId]);

    return (
        <Modal className='slide-more' classNameContainer='slide-more__container' open={isOpened} onClose={onClose} isOverlay={false} isHavePopup={true}>
            <div className='slide-more__icon'>
                <MoreIcon />
            </div>
            <div className='slide-more__list'>
                <div className='slide-more__list-item' onClick={handleComplainOpen}>
                    <ComplainIcon /> Пожаловаться
                </div>
                <Modal
                    className='slide-more__complain'
                    open={isComplainModal}
                    onClose={handleComplainClose}
                    title='Пожаловаться'
                    isHavePopup={true}
                >
                    <Form
                        onSubmit={values => handleComplainUser(values.description)}
                        render={({ handleSubmit }) =>
                            <form onSubmit={handleSubmit}>
                                <Field
                                    name='description'
                                    render={TextAreaInput}
                                    placeholder='Введите текст'
                                    rightIcon={null}
                                    validate={required()}
                                />
                                <Button className='slide-more__complain-button' size='medium'>
                                    Отправить жалобу
                                </Button>
                            </form>
                        }
                    />
                </Modal>
                <div className='slide-more__list-item' onClick={handleBlockUser}>
                    <BlockIcon /> Заблокировать пользователя
                </div>
                <div className='slide-more__list-item' onClick={handleClearChat}>
                    <TrashIcon /> Очистить чат
                </div>
            </div>
        </Modal >
    );
};

export default SlideMorePopup;