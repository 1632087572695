import all from 'ramda/src/all';
import allPass from 'ramda/src/allPass';
import always from 'ramda/src/always';
import and from 'ramda/src/and';
import any from 'ramda/src/any';
import anyPass from 'ramda/src/anyPass';
import append from 'ramda/src/append';
import complement from 'ramda/src/complement';
import compose from 'ramda/src/compose';
import concat from 'ramda/src/concat';
import cond from 'ramda/src/cond';
import converge from 'ramda/src/converge';
import curryN from 'ramda/src/curryN';
import drop from 'ramda/src/drop';
import either from 'ramda/src/either';
import equals from 'ramda/src/equals';
import evolve from 'ramda/src/evolve';
import F from 'ramda/src/F';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import findIndex from 'ramda/src/findIndex';
import flip from 'ramda/src/flip';
import groupBy from 'ramda/src/groupBy';
import gte from 'ramda/src/gte';
import has from 'ramda/src/has';
import head from 'ramda/src/head';
import identity from 'ramda/src/identity';
import ifElse from 'ramda/src/ifElse';
import includes from 'ramda/src/includes';
import is from 'ramda/src/is';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import join from 'ramda/src/join';
import keys from 'ramda/src/keys';
import last from 'ramda/src/last';
import length from 'ramda/src/length';
import lensProp from 'ramda/src/lensProp';
import map from 'ramda/src/map';
import mergeAll from 'ramda/src/mergeAll';
import mergeLeft from 'ramda/src/mergeLeft';
import not from 'ramda/src/not';
import nthArg from 'ramda/src/nthArg';
import or from 'ramda/src/or';
import over from 'ramda/src/over';
import partition from 'ramda/src/partition';
import path from 'ramda/src/path';
import pathEq from 'ramda/src/pathEq';
import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import pickAll from 'ramda/src/pickAll';
import pickBy from 'ramda/src/pickBy';
import pipe from 'ramda/src/pipe';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import propSatisfies from 'ramda/src/propSatisfies';
import reduce from 'ramda/src/reduce';
import replace from 'ramda/src/replace';
import slice from 'ramda/src/slice';
import sortBy from 'ramda/src/sortBy';
import split from 'ramda/src/split';
import splitEvery from 'ramda/src/splitEvery';
import T from 'ramda/src/T';
import tail from 'ramda/src/tail';
import take from 'ramda/src/take';
import tap from 'ramda/src/tap';
import test from 'ramda/src/test';
import toLower from 'ramda/src/toLower';
import trim from 'ramda/src/trim';
import uniqBy from 'ramda/src/uniqBy';
import unless from 'ramda/src/unless';
import useWith from 'ramda/src/useWith';
import values from 'ramda/src/values';
import when from 'ramda/src/when';
import where from 'ramda/src/where';
import whereEq from 'ramda/src/whereEq';

const isNotNil = complement(isNil);

const isNotEmpty = complement(isEmpty);

const isNotEquals = complement(equals);

const isNotPropEq = complement(propEq);

const isNotNilIsNotEmpty = value => isNotEmpty(value) && isNotNil(value);

const flippedIncludes = flip(includes);

const alt = curryN(3, (func1, func2, val) => func1(val) || func2(val));

const logger = label => tap(v => console.log(label, v));

const eqPath = curryN(3, (pathList, value, map) => compose(equals(value), pathOr(null, pathList))(map));

export {
    and,
    append,
    anyPass,
    allPass,
    all,
    alt,
    uniqBy,
    complement,
    concat,
    compose,
    converge,
    evolve,
    join,
    head,
    whereEq,
    where,
    gte,
    isNotNilIsNotEmpty,
    isEmpty,
    split,
    splitEvery,
    mergeAll,
    mergeLeft,
    is,
    has,
    isNotEmpty,
    path,
    keys,
    length,
    last,
    values,
    equals,
    pickAll,
    propEq,
    pluck,
    find,
    filter,
    partition,
    findIndex,
    curryN,
    eqPath,
    flippedIncludes,
    flip,
    ifElse,
    includes,
    isNotPropEq,
    isNotEquals,
    isNotNil,
    isNil,
    or,
    pick,
    logger,
    map,
    pathOr,
    pathEq,
    prop,
    propOr,
    sortBy,
    reduce,
    slice,
    take,
    tap,
    test,
    toLower,
    trim,
    propSatisfies,
    when,
    unless,
    any,
    cond,
    always,
    tail,
    T,
    F,
    identity,
    either,
    useWith,
    drop,
    replace,
    pickBy,
    nthArg,
    not,
    pipe,
    over,
    lensProp,
    groupBy,
};
