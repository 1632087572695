import classNames from 'classnames';
import React, { useCallback } from 'react';

import './switch-input.scss';

const SwitchInput = props => {
    const { label, input, meta, help, choice, checked, isGrayStyle } = props;
    const { value, onChange, name } = input;
    const { touched, valid, error, submitError } = meta;

    const helpText = touched && (error || submitError) || help;

    const onChangeHandler = useCallback(event => {
        onChange(choice[event.target.value === 'true' ? 0 : 1].key);
    }, [choice, onChange]);

    return (
        <div>
            <div className='personal__input-block'>
                {!!label && <label htmlFor={name} className='form__label'>
                    {label}
                </label>}
                <div className='switch__wrap'>
                    <span className={`switch-value  ${choice[1].key === value ? 'switch-value--true' : 'switch-value--false active'}`}>
                        {choice[0].label}
                    </span>
                    <label className={classNames('switch', { 'switch-gray': isGrayStyle })}>
                        <input
                            id={name}
                            name={name}
                            className={classNames('switch__input', { 'switch__input--active': choice[1].key === value })}
                            type='checkbox'
                            value={choice[1].key === value}
                            onChange={onChangeHandler}
                            checked={checked}
                        />
                        <span className='switch__slider'></span>
                    </label>
                    <span className={`switch-value ${choice[1].key === value ? 'switch-value--false active' : 'switch-value--true'}`}>
                        {choice[1].label}
                    </span>
                </div>
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
        </div>
    );
};

export default SwitchInput;
