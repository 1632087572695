import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import './Footer.scss';

const Footer = () => {
    const { asPath } = useRouter();
    const isMainPage = asPath === '/';

    return (
        <footer className={classNames('footer', { footer_main: isMainPage })}>
            <div className='container'>
                <div className='footer__top'>
                    <div className='footer__logo-wrap'>
                        {isMainPage ? (
                            <div className='footer__logo main'>
                                <img src='/assets/favicon.svg' />
                                <span>Юридический сервис</span>
                            </div>
                        ) : (
                            <Link href='/' >
                                <div className='footer__logo'>
                                    <img src='/assets/favicon.svg' />
                                    <span>Юридический сервис</span>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className='footer__links-wrap'>
                        <div className='footer__services-wrap'>
                            <h4 className='footer__links-title'>Услуги</h4>
                            <div className='footer__links-inner'>
                                <ul className='footer__links'>
                                    <li className='footer__link'>
                                        <Link href='/'>Адвокаты</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Нотариусы</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Патентный поверенный</Link>
                                    </li>
                                </ul>
                                <ul className='footer__links'>
                                    <li className='footer__link'>
                                        <Link href='/'>Переводчики</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Судебные эксперы</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Личный кабинет</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='footer__project-wrap'>
                            <h4 className='footer__links-title'>Проект</h4>
                            <div className='footer__links-inner'>
                                <ul className='footer__links'>
                                    <li className='footer__link'>
                                        <Link href='/'>О проекте</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Пресс центр</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Новости</Link>
                                    </li>
                                </ul>
                                <ul className='footer__links'>
                                    <li className='footer__link'>
                                        <Link href='/'>Лицензионное соглашение</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Правовая информация</Link>
                                    </li>
                                    <li className='footer__link'>
                                        <Link href='/'>Контакты</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer__bottom'>
                    <small className='footer__copyright'>©2020 Юридический сервис</small>
                    <div className='footer__support-wrap'>
                        <h3 className='footer__support-tel'>
                            <a href='tel:'>+7 495 593-55-55</a>
                        </h3>
                        <small className='footer__support-info'>Служба поддержки круглосуточно</small>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
