import React from 'react';
import { Field, Form } from 'react-final-form';

import { FormValuesSpy } from '@components';
import { StringInput } from '@form-components';
import MagnifierIcon from '@images/magnifier.svg';

const SpecialistModalFilters = ({ onChange }) => <Form
    onSubmit={onChange}
    render={() => <>
        <FormValuesSpy onChange={onChange} debounceTime={200} />
        <Field
            label=''
            name='search'
            component={StringInput}
            type='text'
            placeholder='Поиск по участникам'
            rightIcon={<MagnifierIcon />}
            primary={true}
        />
    </>
    }
/>;

export default SpecialistModalFilters;
