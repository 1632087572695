import { getPrivateChatsSelector } from 'src/selectors/private-chats-selectors';

import { unexpectedExceptionNotification } from '@components';
import { getCasePageSelector } from '@selectors/case-page-selector';
import axios from '@utils/axios';

const prefix = '@PRIVATE_CHATS';

export const SELECT_PRIVATE_CHAT = `${prefix}/SELECT_PRIVATE_CHAT`;
export const SET_IS_GET_PRIVATE_CHATS_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_PRIVATE_CHATS_REQUEST_PROCESSING`;
export const SET_GET_PRIVATE_CHATS_REQUEST_ERROR = `${prefix}/SET_GET_PRIVATE_CHATS_REQUEST_ERROR`;
export const SET_PRIVATE_CHATS = `${prefix}/SET_PRIVATE_CHATS`;
export const CHANGE_LAST_MESSAGE_AND_COUNT = `${prefix}/CHANGE_LAST_MESSAGE_AND_COUNT`;
export const REMOVE_READ_MESSAGES_FROM_COUNT = `${prefix}/REMOVE_READ_MESSAGES_FROM_COUNT`;

export const selectPrivateChatAction = chatId => ({
    type: SELECT_PRIVATE_CHAT,
    payload: chatId,
});

export const setIsGetPrivateChatsRequestProcessingAction = isProcessing => ({
    type: SET_IS_GET_PRIVATE_CHATS_REQUEST_PROCESSING,
    payload: isProcessing,
});

export const setGetPrivateChatsRequestErrorAction = error => ({
    type: SET_GET_PRIVATE_CHATS_REQUEST_ERROR,
    payload: error,
});

export const setPrivateChatsAction = error => ({
    type: SET_PRIVATE_CHATS,
    payload: error,
});

export const changeLastMessageAndCount = (currentUserId, message) => ({
    type: CHANGE_LAST_MESSAGE_AND_COUNT,
    payload: { currentUserId, message },
});

export const removeReadMessagesFromCount = (chatId, readMessagesCount) => ({
    type: REMOVE_READ_MESSAGES_FROM_COUNT,
    payload: { chatId, readMessagesCount },
});

export const getPrivateChatsAction = search => async (dispatch, getState) => {
    const state = getState();
    const {
        getPrivateChatsRequest: { isProcessing },
    } = getPrivateChatsSelector(state);

    if (isProcessing) {
        return;
    }

    dispatch(setIsGetPrivateChatsRequestProcessingAction(true));

    try {
        const { caseId } = getCasePageSelector(state);
        const { data } = await axios.get(`/api/legal_cases/${caseId}/private_chats?search=${search || ''}`);

        dispatch(setPrivateChatsAction(data.chats));
    } catch (ex) {
        dispatch(setGetPrivateChatsRequestErrorAction(ex));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetPrivateChatsRequestProcessingAction(false));
};
