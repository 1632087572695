import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getNotificationsSelector } from 'src/selectors/notification-selector';
import { getPersonalSelector } from 'src/selectors/personal-selector';

import { logoutUser } from '@actions/auth/logoutUser';
import { getCountNotifications, setCloseNotification, setOpenNotification } from '@actions/notifications';
import { getSpecialistDataAction } from '@actions/specialist/specialist';
import { Logo, NotificationPopup, NumberBadge } from '@components';
import { useAdaptive } from '@hooks';
import BellIcon from '@images/bell.svg';
import { ssrDataSelector } from '@selectors/ssr';

import { SCREEN_TYPES } from '../adaptive-provider';

import './Header.scss';
import '../site-personal-header/PersonalHeader.scss';

const Header = props => {
    const dispatch = useDispatch();
    const { asPath } = useRouter();

    const { basicData } = useSelector(getPersonalSelector);

    const [menuDropdown, setMenuDropdown] = useState(false);

    const areaHitCheck = useCallback(event => {
        if (!event.target.closest('.header-personal__wrap')) {
            setMenuDropdown(false);
        }
    }, []);

    useEffect(() => {
        if (process.browser) {
            document.body.addEventListener('click', areaHitCheck);
        }

        return () => {
            if (process.browser) {
                document.body.removeEventListener('click', areaHitCheck);
            }
        };
    }, [areaHitCheck]);

    const switchMenu = useCallback(() => {
        setMenuDropdown(!menuDropdown);
    }, [menuDropdown]);

    const { screenType, isMobile } = useAdaptive();

    const ssrData = useSelector(ssrDataSelector);
    const menuLinks = useMemo(() => {
        return ssrData?.['site-header']?.categories_specialists || [];
    }, [ssrData]);

    const router = useRouter();
    const {
        query: { flt_category_specialist },
    } = router;
    const logoutHandler = useCallback(() => {
        props.logoutUser();
        router.push('/site/main-page', '/');
    }, [props, router]);

    const userAuthorized = !!props.currentUser.authUserToken;

    const { notificationsCounters, isNotificationOpened } = useSelector(getNotificationsSelector);

    const handleNotificationOpen = () => dispatch(setOpenNotification());

    const handleNotificationClose = () => dispatch(setCloseNotification());

    useEffect(() => {
        if (userAuthorized) {
            dispatch(getCountNotifications());
        }
    }, [dispatch, userAuthorized]);

    return (
        <header className='header'>
            <div className='container'>
                <div className='header__inner'>
                    <div className='header__logo-wrap'>
                        {asPath === '/' ?
                            <Logo isMain={true} />
                            :
                            <Link href='/site/main-page' as='/'>
                                <a>
                                    <Logo />
                                </a>
                            </Link>
                        }
                    </div>

                    {screenType >= SCREEN_TYPES.DESKTOP &&
                        <nav className='header__nav'>
                            <ul className='header__nav-list'>
                                {menuLinks &&
                                    menuLinks.map(link =>
                                        <li className={classNames('header__nav-item', { active: link.key === flt_category_specialist })} key={link.key}>
                                            <Link
                                                href={{
                                                    pathname: '/site/specialists-page',
                                                    query: { isHeaderRouting: true, flt_category_specialist: link.key, page: 1 },
                                                }}
                                                as={`/specialists/${link.key}?page=1`}
                                            >
                                                {link.label}
                                            </Link>
                                        </li>,
                                    )}
                            </ul>
                        </nav>
                    }

                    <div className='header__account'>
                        {!userAuthorized &&
                            (isMobile ?
                                <Link href='/site/login-page' as='/login'>
                                    <a className='login-link'>
                                        <img src='/assets/images/header-key.svg' style={{ marginRight: '50px' }} />
                                    </a>
                                </Link>
                                :
                                <Link href='/site/login-page' as='/login'>
                                    <a className='login-link'>
                                        <img src='/assets/images/header-key.svg' />
                                        Личный кабинет
                                    </a>
                                </Link>
                            )}

                        {userAuthorized &&
                            <>
                                <div className='header-personal__bell' onClick={handleNotificationOpen}>
                                    <BellIcon />
                                    {!!notificationsCounters.count_unread &&
                                        <NumberBadge className='header-personal__bell-notice'>{notificationsCounters.count_unread}</NumberBadge>
                                    }
                                </div>
                                <NotificationPopup isOpen={isNotificationOpened} onClose={handleNotificationClose} />
                                <div className='header-personal__wrap' onClick={switchMenu}>
                                    <div className='header-personal__avatar'>
                                        <div className='header-personal__avatar-img' style={{ backgroundImage: `url(${props.currentUser.avatar})` }} />
                                    </div>
                                    {screenType === SCREEN_TYPES.BIG_SCREEN &&
                                        <div className='header-personal__name-wrap'>
                                            <div className='header-personal__surname'>{props.currentUser.userLastName}</div>
                                            <div className='header-personal__name'>{props.currentUser.userName}</div>
                                        </div>
                                    }
                                    <button className='header-personal__btn' />
                                    {menuDropdown &&
                                        <div className='header-personal__dropdown-menu'>
                                            <div className='header-personal__item'>
                                                <Link href='/site/personal-page/workspace' as='/personal/workspace'>
                                                    Личный кабинет
                                                </Link>
                                            </div>
                                            <div className='header-personal__item' onClick={logoutHandler}>
                                                Выход
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {/* {screenType < SCREEN_TYPES.DESKTOP &&
                            <div className='header__hamburger-menu'>
                                <div className='header__hamburger-menu-icon' ref={hamburgerMenuIconRef}>
                                    {isHamburgerMenuOpen ?
                                        <MenuCloseButtonIcon onClick={toggleHamburgerMenu} className='close' />
                                        :
                                        <MenuOpenButtonIcon onClick={toggleHamburgerMenu} />
                                    }
                                </div>
                                {isHamburgerMenuOpen &&
                                    <div className='header__hamburger-menu-list'>
                                        {menuLinks &&
                                            menuLinks.map(link =>
                                                <div
                                                    className={classNames('header__nav-item', { active: link.key === flt_category_specialist })}
                                                    key={link.key}
                                                >
                                                    <Link
                                                        href={{
                                                            pathname: '/site/specialists-page',
                                                            query: { isHeaderRouting: true, flt_category_specialist: link.key, page: 1 },
                                                        }}
                                                        as={`/specialists/${link.key}?page=1`}
                                                    >
                                                        {link.label}
                                                    </Link>
                                                </div>,
                                            )}
                                    </div>
                                }
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </header>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => dispatch(logoutUser()),
        getSpecialistDataAction: data => dispatch(getSpecialistDataAction(data)),
    };
}

export default connect(
    state => ({
        currentUser: state.currentUser,
        page: state.page.currentPage,
    }),
    mapDispatchToProps,
)(Header);
