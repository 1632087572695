import React from 'react';

import { Button, Modal } from '@components';
import { CloseIcon } from '@images/close.svg';
import { TrashIcon } from '@images/trash.svg';

import './confirm-modal.scss';

const ConfirmModal = ({ className, isOpened, onClose, onAccept, message, title, isIcons = false }) => {
    return <Modal className={`confirm-modal ${className}`} open={isOpened} title={title} onClose={onClose}>
        <p className='confirm-modal__message'>{message}</p>
        <div className='confirm-modal__controls'>
            <Button
                className='confirm-modal__accept'
                startIcon={isIcons ? <TrashIcon /> : null}
                size='medium'
                onClick={() => {
                    onAccept();
                    onClose();
                }}
            >
                Да
            </Button>
            <Button
                className='confirm-modal__cancel'
                startIcon={isIcons ? <CloseIcon /> : null}
                view='primary-outline'
                size='medium'
                onClick={onClose}
            >
                Нет
            </Button>
        </div>
    </Modal>;
};

export default ConfirmModal;
