import React from 'react';
import { useSelector } from 'react-redux';
import { isGlobalSpinnerVisibleSelector } from 'src/selectors/global-selector';

import { Spinner } from '@components';

import './global-spinner.scss';

const GlobalSpinner = () => {
    const isVisible = useSelector(isGlobalSpinnerVisibleSelector);

    if (!isVisible) {
        return null;
    }

    return (
        <div className='global-spinner'>
            <Spinner />
        </div>
    );
};

export default GlobalSpinner;
