import React, { useEffect, useMemo } from 'react';

import { useAdaptive } from '@hooks';
import { findIndex } from '@utils/ramda';

import { Tabs } from '../tabs';

import './adaptive-tabs.scss';

const AdaptiveTabs = ({ tabs, isAdaptiveEnabled, selected, onSelect }) => {
    const { isMobile, isTablet } = useAdaptive();
    const isMobileOrTablet = isMobile || isTablet;

    const tabsForRender = useMemo(() => (isMobileOrTablet ? tabs : tabs.filter(({ key }) => key !== 'default')), [isMobileOrTablet, tabs]);

    const selectedIndex = useMemo(() => findIndex(({ key }) => key === selected)(tabsForRender), [selected, tabsForRender]);

    const desktopView = useMemo(() => <Tabs tabs={tabsForRender} selected={selected} onSelect={onSelect} />, [onSelect, selected, tabsForRender]);

    useEffect(() => {
        const style = document.body.style;

        if (isMobileOrTablet) {
            if (selected === 'default') {
                style.overflow = 'auto';
            } else {
                style.overflow = 'hidden';
            }
        } else {
            style.overflow = 'auto';
        }

        if (!isMobileOrTablet && style.overflow === 'auto' && selected === 'default') {
            onSelect(tabs[1].key);
        }
    }, [isMobileOrTablet, onSelect, selected, tabs]);

    const tabletAndMobileView = useMemo(
        () =>
            <>
                {selected !== 'default' && <div className='adaptive-tabs__adaptive-content'>{tabs[selectedIndex]?.content}</div>}
                {/* {TODO: I think it needs delete} */}
                {/* <IconsMenu icons={tabs} active={selected} onSetActive={onSelect} countIconsInRow={isTablet ? 6 : 3} /> */}
            </>
        ,
        [selected, selectedIndex, tabs],
    );

    return <div className='adaptive-tabs'>{isAdaptiveEnabled && isMobileOrTablet ? tabletAndMobileView : desktopView}</div>;
};

export default AdaptiveTabs;
