import React from 'react';

import './spinner.scss';

const Spinner = ({ mode }) => {
    if (mode === 'over') {
        return <div className='spinner spinner_over'>
            <div className='loader spinner__icon' />
        </div>;
    }

    return <div className='loader' />;
};

export default Spinner;
