import axios from 'axios';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';

import { svgStarPath } from '@constants';
import { TextAreaInput } from '@form-components';
import { useModal } from '@hooks';

import { Button } from '../button';
import { unexpectedExceptionNotification } from '../notification';

import './review-card.scss';

const StarRatings = dynamic(() => import('react-star-ratings'), { ssr: false });

const ReviewCard = ({ review, refetchReviews, isCurrentUser }) => {
    const [isOpenedAnswer, handleOpenAnswer, handleCloseAnswer] = useModal();

    const onSubmit = useCallback(async values => {
        try {
            await axios.post(`/api/reviews/${review.id}/answers`, {
                description: values.description,
            });
            await refetchReviews();
            handleCloseAnswer();
        } catch (error) {
            unexpectedExceptionNotification();
        }
    }, [handleCloseAnswer, refetchReviews, review.id]);

    return (
        <div className='review-card' key={review.id}>
            <div className='review-card__wrapper'>
                <img className='review-card__avatar' src={review.author.avatar} />
                <div className='review-card__column'>
                    <p className='review-card__name'>{review.author.name}</p>
                    <div className='review-card__rating'>
                        <StarRatings
                            numberOfStars={5}
                            rating={review.rating}
                            starDimension='20px'
                            starSpacing='4px'
                            starEmptyColor='#DCDEE4'
                            starRatedColor='#F8E5B8'
                            svgIconPath={svgStarPath}
                        />
                        <span>{format(new Date(review.created_at), 'dd.MM.yyyy')}</span>
                    </div>
                    <p className='review-card__description'>{review.description}</p>
                </div>
            </div>
            {isOpenedAnswer ? <Form onSubmit={onSubmit} render={({ handleSubmit }) => <form onSubmit={handleSubmit}>
                <Field name='description' placeholder='Оставьте отзыв...' component={TextAreaInput} />
                <div className='review-card__answers-actions'>
                    <Button
                        className='review-card__btn'
                        view='primary-outline'
                        size='medium'
                        type='button'
                        onClick={handleCloseAnswer}
                    >
                        Отменить
                    </Button>
                    <Button
                        className='review-card__btn'
                        view='primary'
                        size='medium'
                    >
                        Сохранить
                    </Button>
                </div>
            </form>} /> : review.answers.length ? review.answers.map(answer => <div className='review-card__answer' key={answer.id}>
                <h6 className='review-card__answer-title'>{isCurrentUser ? 'Мой ответ' : 'Ответ исполнителя'}</h6>
                <p className='review-card__answer-description'>{answer.description}</p>
            </div>) : isCurrentUser ? <Button
                className='review-card__btn'
                view='primary-outline'
                size='medium'
                type='button'
                onClick={handleOpenAnswer}
            >
                Ответить
            </Button> : null}
        </div>
    );
};

export default ReviewCard;