import axios from 'axios';
import { format } from 'date-fns';
import Link from 'next/link';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsSelector } from 'src/selectors/notification-selector';
import { Modal } from '@material-ui/core';

import { getNotifications, resetPaginationNotification, setCurrentPageAction } from '@actions/notifications';
import { useModal } from '@hooks';
import { BackIcon } from '@images/back.svg';
import { CloseIcon } from '@images/close.svg';
import { MoreIcon } from '@images/more.svg';
import SettingsIcon from '@images/settings.svg';
import { DeleteIcon } from '@images/trash.svg';

import { unexpectedExceptionNotification } from '../notification/Notification';

import './notification-popup.scss';

const NotificationItem = ({ item }) => {
    const dispatch = useDispatch();
    const [isMoreOpened, handleMoreOpen, handleMoreClose] = useModal();

    const handleReadNotification = useCallback(async id => {
        try {
            await axios.put('/api/notifications', { notifications: [id] });
            dispatch(resetPaginationNotification());
            dispatch(setCurrentPageAction(0));
            dispatch(getNotifications());
            handleMoreClose();
        } catch (err) {
            unexpectedExceptionNotification();
        }
    }, [dispatch, handleMoreClose]);

    const handleDeleteNotification = useCallback(async id => {
        try {
            await axios.delete('/api/notifications', { data: { notifications: [id] } });
            dispatch(resetPaginationNotification());
            dispatch(setCurrentPageAction(0));
            dispatch(getNotifications());
            handleMoreClose();
        } catch (err) {
            unexpectedExceptionNotification();
        }
    }, [dispatch, handleMoreClose]);

    return (
        <div className='notification-popup__item'>
            <div className='notification-popup__item-more' onClick={handleMoreOpen}>
                <MoreIcon />
            </div>
            <h6 className='notification-popup__item-title'>{item.title}</h6>
            <p className='notification-popup__item-descr'>{item.description}</p>
            <p className='notification-popup__item-date'>{format(new Date(item.created_at), 'dd.MM.yyyy HH:mm')}</p>
            <Modal
                style={{ position: 'absolute', cursor: 'default' }}
                BackdropProps={{ invisible: true }}
                open={isMoreOpened}
                onClose={handleMoreClose}
                disablePortal={true}
            >
                <div className='notification-popup__more-popup'>
                    <div className='notification-popup__item-more_active'>
                        <MoreIcon />
                    </div>
                    <div className='notification-popup__more-popup__actions'>
                        {!item.read_at && <div className='notification-popup__more-popup__actions-item' onClick={() => handleReadNotification(item.id)}>
                            Отметить как прочитанное
                        </div>}
                        <div className='notification-popup__more-popup__actions-item' onClick={() => handleDeleteNotification(item.id)}>
                            <DeleteIcon /> Удалить
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const NotificationPopup = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    const {
        notificationsRequest: { notificationsResponse }, currentPage,
        notifications,
    } = useSelector(getNotificationsSelector);

    useEffect(() => {
        if (isOpen) {
            dispatch(getNotifications());
        }
    }, [dispatch, isOpen]);

    const readAllMessages = useCallback(async () => {
        try {
            await axios.post('/api/notifications');
        } catch (err) {
            unexpectedExceptionNotification();
        }
    }, []);

    const isShowNotifications = useMemo(() => !!notifications.length, [notifications.length]);

    const myRef = useRef(null);

    const handleScroll = useCallback(() => {
        if (myRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = myRef.current;

            if (scrollTop + clientHeight >= scrollHeight) {
                if (currentPage + 1 !== notificationsResponse?.meta?.last_page) {
                    dispatch(setCurrentPageAction(currentPage + 1));
                    dispatch(getNotifications());
                }
            }
        }
    }, [currentPage, dispatch, notificationsResponse]);

    useEffect(() => {
        const scrollableElement = myRef.current;

        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const handleCloseNotification = () => {
        dispatch(setCurrentPageAction(0));
        dispatch(resetPaginationNotification());
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={handleCloseNotification}>
            <div className='notification-popup'>
                <button type='button' className='notification-popup__close' onClick={handleCloseNotification}>
                    <CloseIcon />
                </button>
                <div className='notification-popup__header'>
                    <div className='notification-popup__header-wrapper'>
                        <BackIcon className='notification-popup__back' onClick={handleCloseNotification} />
                        <h3 className='notification-popup__header-title'>Уведомления</h3>
                    </div>
                    <div className='notification-popup__header-actions'>
                        {isShowNotifications && <p className='notification-popup__header-actions__read' onClick={readAllMessages}>
                            Отметить все, как прочитанные
                        </p>}
                        <Link href='/site/personal-page/notifications' as='/personal/notifications'>
                            <a>
                                <SettingsIcon className='notification-popup__header-actions__settings' />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className='notification-popup__content' ref={myRef}>
                    {isShowNotifications ? notifications.map(item =>
                        <NotificationItem key={item.id} item={item} />,
                    ) : <p>У вас пока нет уведомлений...</p>}
                </div>
            </div>
        </Modal>
    );
};

export default NotificationPopup;
