import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { StringInput } from '@form-components';

import './double-input.scss';

const DoubleInput = ({ className, label, required, leftInput, rightInput, secondary = false }) => {
    return (
        <div className={`double-input ${className}`}>
            <label className='double-input__label'>{label}</label>
            <div className='double-input__row'>
                <Field
                    className='double-input__left'
                    name={leftInput.name}
                    component={StringInput}
                    type='text'
                    placeholder={leftInput.placeholder}
                    required={required}
                    secondary={secondary}
                />
                <Field
                    className='double-input__right'
                    name={rightInput.name}
                    component={StringInput}
                    type='text'
                    placeholder={rightInput.placeholder}
                    required={required}
                    secondary={secondary}
                />
            </div>
        </div>
    );
};

DoubleInput.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    leftInput: PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    }).isRequired,
    rightInput: PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    }).isRequired,
    secondary: PropTypes.bool,
};

export default DoubleInput;