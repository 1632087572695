import React, { useCallback, useState } from 'react';

import { Skeleton } from '@components';
import { useAdaptive } from '@hooks';
import DropdownIcon from '@images/accordion-dropdown.svg';

import './table.scss';

const renderSkeletons = column => {
    if (!column.skeletons || !Array.isArray(column.skeletons)) {
        return <Skeleton width='100%' />;
    }

    return column.skeletons.map((width, index) => <Skeleton key={index} width={width} />);
};

const Table = props => {
    const { columns = [], data = [], emptyPlaceholder, isAdaptiveEnabled, onSortChange } = props;
    const [sortColumn, setSortColumn] = useState('name');
    const [sortType, setSortType] = useState('asc');

    const { isMobile } = useAdaptive();

    const onSortHandler = useCallback(column => {
        if (!columns.find(({ key, isSortable }) => isSortable && key === column)) {
            return;
        }

        if (sortColumn === column) {
            setSortType(sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortType('asc');
        }

        if (onSortChange) {
            onSortChange(`${sortColumn}_${sortType}`);
        }
    }, [columns, onSortChange, sortColumn, sortType]);

    if (isAdaptiveEnabled && isMobile) {
        return <>
            <div className='mobile-table'>
                {data && data.length !== 0 && data.map(row => <div key={`row-${row.id || row.public_id}`} className='mobile-table__item'>
                    {columns.map(column => <div key={column.key} className={column.cellClassName}>
                        {column.cellRender({ value: row[column.key], row })}
                    </div>)}
                </div>)}
                {data?.length === 0 && <div className='mobile-table__item'>
                    {columns.map(column => <div key={column.key}>
                        {renderSkeletons(column)}
                    </div>)}
                </div>}
            </div>
            {data?.length === 0 && emptyPlaceholder && <div className='table__empty-placeholder'>{emptyPlaceholder}</div>}
        </>;
    }

    return <>
        <table className='table'>
            <thead>
                <tr>{columns.map(({ key, name, isSortable }) =>
                    <td key={key} className={isSortable ? 'sortable' : ''} onClick={() => onSortHandler(key)}>
                        {name}
                        {isSortable && <div className={`table__sort ${sortColumn === key && `table__sort_${sortType}`}`}>
                            <DropdownIcon />
                            <DropdownIcon />
                        </div>}
                    </td>)}
                </tr>
            </thead>
            <tbody>
                {data && data.length !== 0 && data.map(row => <tr key={`row-${row.id || row.public_id}`}>
                    {columns.map(column => <td key={column.key} className={column.cellClassName}>
                        {column.cellRender({ value: row[column.key], row })}
                    </td>)}
                </tr>)}
                {data?.length === 0 && <tr>
                    {columns.map(column => <td key={column.key}>
                        {renderSkeletons(column)}
                    </td>)}
                </tr>}
            </tbody>
        </table>
        {data?.length === 0 && emptyPlaceholder && <div className='table__empty-placeholder'>{emptyPlaceholder}</div>}
    </>;
};

export default Table;
