import {
    BarController, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineController,
    LineElement, PointElement, Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(LineElement, BarElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip, Legend, LineController, BarController);

const BarChart = ({ data, options }) => {
    return (
        <Bar data={data} options={options} />
    );
};

export default BarChart;
