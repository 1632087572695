const REQUIRED_DEFAULT_MESSAGE = 'Поле не заполнено';

export const phoneRequired = (msg = REQUIRED_DEFAULT_MESSAGE) => {
    return (value) => (!value || value.replace(/[^0-9]/g, '').length === 0 ? msg : null);
};

const VALIDATE_DEFAULT_MESSAGE = 'Неверный формат номера телефона';

export const phoneValidator = (msg = VALIDATE_DEFAULT_MESSAGE) => {
    return (value) => {
        if (!value) {
            return null;
        }

        const phoneNumbers = value.replace(/[^0-9]/g, '');

        if (phoneNumbers.length !== 0 && phoneNumbers.length !== 11) {
            return msg;
        }

        return null;
    };
};
