import React from 'react';
import Slider from 'react-slick';

import { SlickButtonFix } from '@components';

import './faq-tips-slider.scss';

const tipsSlider = {
    dots: true,
    arrows: true,
    swipeToSlide: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots faq-tips-slider__dotnav',
    prevArrow: <SlickButtonFix>
        <button className='faq-tips-slider__prev__btn'>
            <svg
                width='10'
                height='16'
                viewBox='0 0 10 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M9 15L2 8L9 1'
                    stroke='#1C6FD1'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                />
            </svg>
        </button>
    </SlickButtonFix>,
    nextArrow: <SlickButtonFix>
        <button className='faq-tips-slider__next__btn'>
            <svg
                width='10'
                height='16'
                viewBox='0 0 10 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M1 15L8 8L0.999999 1'
                    stroke='#1C6FD1'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                />
            </svg>
        </button>
    </SlickButtonFix>,
};

const FaqTipsSlider = () => {
    return <div className='faq-tips-slider__wrap'>
        <h2 className='faq-tips-slider__title'>Краткие советы</h2>
        <div className='faq-tips-slider__inner'>
            <Slider {...tipsSlider}>
                <div className='faq-tips-slider__item'>
                    <p className='faq-tips-slider__item__title'>
                        Как правильно подобрать адвоката
                    </p>
                    <a href='' className='faq-tips-slider__play'></a>
                </div>
                <div className='faq-tips-slider__item'>
                    <p className='faq-tips-slider__item__title'>
                        Как правильно подобрать адвоката
                    </p>
                    <a href='' className='faq-tips-slider__play'></a>
                </div>
            </Slider>
        </div>
    </div>;
};

export default FaqTipsSlider;
