import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import './faq-tips.scss';

const FaqTips = ({ title, listFaqTips }) => {
    return <div className='faq-tips'>
        <h3 className='faq-tips__title'>{title}</h3>
        <ul className='faq-tips__list'>
            {listFaqTips.map((item, index) => <li key={index} className='faq-tips__item'>
                <Link href={item.href}>
                    {item.title}
                </Link>
            </li>)}
        </ul>
    </div>;
};

FaqTips.propTypes = {
    title: PropTypes.string.isRequired,
    listFaqTips: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default FaqTips;
