import React from 'react';

import { ArrowIcon } from '@images/arrow.svg';

import './mobile-header.scss';

const MobileHeader = ({ title, counters }) => {
    return (
        <div className='mobile-header'>
            <ArrowIcon />
            <h3 className='mobile-header__title'>
                {title}
                {counters && <span>{counters}</span>}
            </h3>
        </div>
    );
};

export default MobileHeader;