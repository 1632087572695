import React, { createContext, useCallback, useEffect, useState } from 'react';

export const AdaptiveContext = createContext('adaptive');

export const SCREEN_TYPES = {
    MOBILE: 1,
    SMALL_MOBILE: 2,
    TABLET: 3,
    DESKTOP: 4,
    BIG_SCREEN: 5,
};

export const AdaptiveProvider = props => {
    const [adaptive, setAdaptive] = useState({});

    const handlerResize = useCallback(() => {
        const width = window.innerWidth;

        let screenType = SCREEN_TYPES.MOBILE;

        if (width < 420) {
            screenType = SCREEN_TYPES.SMALL_MOBILE;
        } else if (width >= 420 && width < 784) {
            screenType = SCREEN_TYPES.MOBILE;
        } else if (width >= 784 && width < 1056) {
            screenType = SCREEN_TYPES.TABLET;
        } else if (width >= 1056 && width < 1424) {
            screenType = SCREEN_TYPES.DESKTOP;
        } else if (width >= 1424) {
            screenType = SCREEN_TYPES.BIG_SCREEN;
        }

        setAdaptive({
            width,
            screenType,
            isMobile: screenType === SCREEN_TYPES.MOBILE,
            isSmallMobile: screenType === SCREEN_TYPES.SMALL_MOBILE,
            isTablet: screenType === SCREEN_TYPES.TABLET,
            isDesktop: screenType === SCREEN_TYPES.DESKTOP,
            isBigScreen: screenType === SCREEN_TYPES.BIG_SCREEN,
        });
    }, []);

    useEffect(() => {
        if (process.browser) {
            handlerResize();
            window.addEventListener('resize', handlerResize);
        }

        return () => {
            if (process.browser) {
                window.removeEventListener('resize', handlerResize);
            }
        };
    }, [handlerResize]);

    return <AdaptiveContext.Provider value={adaptive}>{props.children}</AdaptiveContext.Provider>;
};
