import { maxLength } from './maxLength';
import { minLength } from './minLength';

export const passwordValidator = (email) => {
    return (value) => {
        if (!value) {
            return null;
        }

        let error = null;

        error = minLength(8, 'Пароль должен содержать хотя бы 8 символов')(value);
        if (error) {
            return error;
        }

        error = maxLength(25, 'Пароль должен содержать менее 25 символов')(value);
        if (error) {
            return error;
        }

        if (!/[0-9]+/g.test(value)) {
            return 'Пароль должен содержать хотя бы одну цифру';
        }

        if (!/[а-яёa-z]+/g.test(value)) {
            return 'Пароль должен содержать хотя бы одну букву нижнего регистра';
        }

        if (!/[А-ЯЁA-Z]+/g.test(value)) {
            return 'Пароль должен содержать хотя бы одну букву верхнего регистра';
        }

        if (email && email.toLowerCase() === value.toLowerCase()) {
            return 'Пароль не должен совпадать с email';
        }

        return null;
    };
};
