import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Dropdown.scss';

class Dropdown extends Component {
    render() {
        const { children, label, className } = this.props;

        const classes = classNames(
            'dropdown',
            'uk-inline',
            className,
        );

        return (
            <div className={classes}>
                <button className='dropdown-btn'>
                    {label}
                    <svg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M2.27622 1.33335H5.72394L4.00008 3.05721L2.27622 1.33335Z' strokeWidth='1.33333' strokeMiterlimit='10' />
                    </svg>
                </button>
                <div uk-dropdown='mode: click'>
                    <ul className='dropdown__list'>
                        {children}
                    </ul>
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
};

Dropdown.defaultProps = {
    label: 'Drodpown btn',
    className: '',
};

export default Dropdown;
