import axios from '@utils/axios';

export const getCaseHistory = async caseId => {
    const response = await axios.get(`/api/legal_cases/${caseId}/history`);

    return response.data;
};

export const getCasesQuantity = async queryParams => {
    return await axios.get(`/api/legal_cases?${queryParams}`);
};
