import React from 'react';
import ReactPaginate from 'react-paginate';

import './pagination.scss';

const Pagination = props => {
    const { lastPage, onPageChange, initialPage, forcePage } = props;

    return <ReactPaginate
        pageCount={lastPage}
        onPageChange={event => onPageChange(event)}
        initialPage={initialPage}
        forcePage={forcePage}
        nextLabel={<button className='pagination__btn pagination__btn--next'></button>}
        previousLabel={<button className='pagination__btn pagination__btn--prev'></button>}
        previousClassName='personal__array-pagination pagination__wrap--prev'
        nextClassName='personal__array-pagination pagination__wrap--next'
        marginPagesDisplayed={1}
        activeClassName='active'
        breakClassName='pagination__item pagination__item--none'
        containerClassName='pagination'
        pageClassName='pagination__item'
    />;
};

export default Pagination;
