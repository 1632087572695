import React from 'react';
import { store } from 'react-notifications-component';

import { notificationStatuses, SUCCESS_NOTIFICATION, UNEXPECTED_EXCEPTION_NOTIFICATION } from '@constants';
import ErrorIcon from '@images/notification-error.svg';
import ReminderIcon from '@images/notification-reminder.svg';
import SuccessIcon from '@images/notification-success.svg';

import './Notification.scss';

const Notification = ({ text, type }) => {
    const selectionRequiredUrl = type => {
        if (type === notificationStatuses.success) {
            return <SuccessIcon />;
        } else if (type === notificationStatuses.error) {
            return <ErrorIcon />;
        } else if (type === notificationStatuses.reminder) {
            return <ReminderIcon />;
        }
    };

    return (
        <div className={`notification__block notification__block--${type}`}>
            <div className={`notification__wrap notification__wrap--${type}`}>{selectionRequiredUrl(type)}</div>
            <p className='notification__text'>{text}</p>
        </div>
    );
};

export const createNotification = (text, type) => {
    store.addNotification({
        content: <Notification text={text} type={type} />,
        type: `${type}`,
        container: 'top-center',
        dismiss: {
            duration: 10000,
            showIcon: true,
        },
    });
};

export const unexpectedExceptionNotification = () => createNotification(UNEXPECTED_EXCEPTION_NOTIFICATION, notificationStatuses.error);
export const successNotification = () => createNotification(SUCCESS_NOTIFICATION, notificationStatuses.success);