import Link from 'next/link';
import React, { Component } from 'react';

import './HeaderMinimal.scss';

class HeaderMinimal extends Component {
    render() {
        return (
            <header className='header-minimal'>
                <div className='header-minimal__container'>
                    <Link href='/site/main-page' as='/'>
                        <a className='header-minimal__logo'>
                            <svg width='56' height='56' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'> <g clipPath='url(#clip0)'> <path d='M20.0761 0C20.0761 0 12.9137 28.5823 12.457 30.4762C11.5437 34.4156 12.457 38.0952 16.2665 40C10.4059 40 -1.90479 40 -1.90479 40L-1.90479 0H20.0761Z' fill='#CFE2F3' /> <path fillRule='evenodd' clipRule='evenodd' d='M39.9999 0L21.9047 2.04359e-06V26.6667C18.2343 26.6667 15.238 29.6629 15.238 33.3333C15.238 37.0037 18.2343 40 21.9047 40H39.9999V0Z' fill='#193B7D' /> </g> <defs> <clipPath id='clip0'> <rect width='40' height='40' fill='white' /> </clipPath> </defs> </svg>
                            <span>
                                Юридический
                                <br />
                                сервис
                            </span>
                        </a>
                    </Link>
                </div>
            </header>
        );
    }
}

export default HeaderMinimal;
